<section>
    <div class="section page-banner-section" style="background-image: url('../../../../assets/img/inner-banner.png');">
        <div class="shape-2"></div>
        <div class="container">
            <div class="page-banner-wrap">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Page Banner Content Start -->
                        <div class="page-banner text-center">
                            <h2 class="title">Terms and Conditions</h2>
                            <ul class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Terms and Conditions </li>
                            </ul>
                        </div>
                        <!-- Page Banner Content End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </section>
    
    <section class="pt-4 pb-4">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
    
                    <div class="">
                        <div class="">
                            <h5 class="title">Terms and Conditions</h5>
                           <p class="text-dark"> This website's contents and related graphics cannot be used, copied, or distributed for commercial purposes inside or outside of your organisation.</p>
                       <p class="text-dark">Without express, written prior consent, the name of Samvaad Infotech, the Samvaad logo, banners, or any other company-related information may not be utilised in advertising or publicity linked to the distribution of this content. Regarding the applicability of this material for any purpose, Samvaad provides no guarantees.
                        </p>
                        <p class="text-dark">Based on publicly accessible data, internally generated data, and other sources, the information in these pages and the services provided by Samvaad is subject to change at any time without prior notice.</p>
                       <p class="text-dark">
                        There hasn't been any independent investigation into these sources. It is a statement of an opinion and is predicated on assumptions and restrictions.
                       </p>

                        </div>
                    </div>
    
    
                </div>
            
            </div>
        </div>
    </section>