import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICourse } from 'src/app/components/Shared/models/course';

@Component({
  selector: 'app-autocomplete-demo',
  templateUrl: './autocomplete-demo.component.html',
  styleUrls: ['./autocomplete-demo.component.css']
})
export class AutocompleteDemoComponent implements OnInit {
  keyword = 'COURSE_NAME';
  @Input() allCourseList: Array<ICourse> = [];
  CourseList: Array<ICourse> = [];
  @Output() categoryEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this.CourseList = [...this.allCourseList];
  }

  selectEvent(item) {
    // do something with selected item
    this.categoryEvent.emit(item.COURSE_ID);
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.

    const regex = new RegExp(search, 'i');
    this.CourseList = this.allCourseList.filter(e => regex.test(e.COURSE_NAME));
    // console.log(this.CourseList);
  }

  onFocused(e) {
    // do something
  }

  goBack() {
    window.history.back();
  }
}
