<section id="refundpolicy">
    <div class="section page-banner-section" style="background-image: url('../../../../assets/img/inner-banner.png');">
        <div class="shape-2"></div>
        <div class="container">
            <div class="page-banner-wrap">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Page Banner Content Start -->
                        <div class="page-banner text-center">
                            <h2 class="title">Refund Policy</h2>
                            <ul class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Refund Policy </li>
                            </ul>
                        </div>
                        <!-- Page Banner Content End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </section>
    
    <section class="pt-4 pb-4">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
    
                    <div class="">
                        <div class="">
                            <h5 class="title">Refund Policy</h5>
                           <p class="text-dark mb-0"> Refunds are made to the to your bank account, once we receive your refund request as applicable within the timeframes.</p>

                           <p class="text-dark fw-bold">
                            Refund Timelines
                           </p>
                           <table class="table table-bordered table-sm " >
                            <tbody>
                            <tr>
                                <td>Available payment Method</td>
                                <td>Refund Time frame</td>
                            </tr>
                            <tr>
                                <td>Credit Card/Debit card</td>
                                <td>7 business days</td>
                            </tr>
                            <tr>
                                <td>Net banking Account</td>
                                <td>7 business days</td>
                            </tr>
                            <tr>
                                <td>UPI Linked Bank Account</td>
                                <td>7 business days</td>
                            </tr>
                        </tbody>
                           </table>
                           <h5 class="title">  Refund Guidelines </h5>
                         
                       <p class="text-dark">
                        Any time a refund is necessary, the Company must approve the refund. Samvaad InfoTech can only help you with refunds when the Trainee notifies us for the refund request The aforesaid refund time limit will be within 7 business days after confirmation from the trainee.
                        </p>
                        <p class="text-dark">The refund will be given to you via a demand draft if the payment method you used to make the purchase (such as a credit or debit card) is no longer valid.</p>
                       <p class="text-dark">
                        A demand draft will be used to issue your refund if we get a payment failure notice from your bank.
                       </p>
                       <p class="text-dark">
                        Your name and the address where you want the demand draft sent will be requested over the phone when you call us in response to our email, which we will send to you. The refund process will begin once we get these details.
                       </p>
                        </div>
                    </div>
    
    
                </div>
            
            </div>
        </div>
    </section>