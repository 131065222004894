import { Component, HostListener, OnInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { DomSanitizer } from '@angular/platform-browser';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  
  activeMenu: string = ''; // Variable to store the active menu ID

  @HostListener('click') onClick() {
    const submenu = this.el.nativeElement.nextElementSibling;
    if (submenu) {
      this.renderer.removeClass(submenu, 'collapse');
      this.renderer.addClass(submenu, 'show');
    }
  }
  // Method to toggle the active menu
  toggleActiveMenu(menuId: string) {
    if (this.activeMenu === menuId) {
      this.activeMenu = ''; // Close the menu if it's already open
    } else {
      this.activeMenu = menuId; // Open the menu if it's closed
    }
  }

  roleId: number | string = localStorage.getItem('RoleId') || 3;
  menus: Array<any> = []
  clientLogo: any;
  isLogo: boolean = false;

  constructor(private renderer: Renderer2, private route: ActivatedRoute, private router: Router, private service: CommonService, private sanitizer: DomSanitizer,private el: ElementRef) {
    this.sideBarMoving();
    this.loadScript();
    this.getMenu();

    if (localStorage.getItem('Logo') == "") {
      this.isLogo = true;
    } else {
      this.isLogo = false;
    }

  }

  ngOnInit(): void {
    this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${localStorage.getItem('Logo')}`);
  }

  getClass(name) {
    return "mdi " + name + " menu-icon"
  }

  loadScript() {
    const script = this.renderer.createElement('script');
    script.src = 'assets/plugins/metismenu/js/metisMenu.min.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }

  navigate(path, menu) {
    if (!menu.childs.length && path) {
      this.router.navigate(['HOME/' + path])
    }
  }

  getMenu() {
    let payLoad = {
      RoleId: parseInt(localStorage.getItem('RoleId')), TENANT_CODE: parseInt(localStorage.getItem('TenantCode'))
    }
    this.service.postCall('Account/LoadMenusByRoleId', payLoad).subscribe((res) => {
      this.menus = res;
    }, err => { })
  }

  getId(id: any) {
    return id.replaceAll(/\s/g, '')
  }

  //  smartHr(){
  //   window.open('https://smhrqainternal.dhanushinfotech.com/Admin');
  //  }

  closeLeftMenuforMobile() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      const idClickEvent = document.getElementById('sidebar');
      if (idClickEvent) {
        idClickEvent.classList.value = 'wrapper';
      }
    }
  }

  sideBarMoving() {
    $(function () {
      "use strict";

      $(".mobile-toggle-menu").on("click", function () {
        $(".wrapper").addClass("toggled")
      }),
        $(".toggle-icon").click(function () {
          $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function () {
            $(".wrapper").addClass("sidebar-hovered")
          }, function () {
            $(".wrapper").removeClass("sidebar-hovered")
          }))
        })
    });
  }

  // Method to toggle menu
  
}
