<li class="nav-item dropdown dropdown-large">
    <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
        data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">{{TeachersCount}}</span>
        <!-- <img style="height: 24px;"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAARJJREFUWEftl90NwjAMhL9OAGzABrABsAEbwGawATABjAAbMAJMADqpraKoxWkpSoTilz44bi93/mtBYlYkhocMyFIkM/QXDE2BBaDnEHYHTsAj5GV+Ds2BMzAOCe5wRmBWwNWK8QEpYGYF9fSLpbUV6wN6WQFf+puqWqrUzMUGtAQOwKS6aExAW2BXAqlxxAK0BzaO/NEASRpJJKlciwZIyask9i0aoLYizYCs9pUZqhi6tYymaAyp7I/lNpFE2VdMJNMY3cmQ1Oio5FLHloT1/hVrlrn583H9uDQknNVDQv29FjShFahR6FcCzz3Lgdp5hdX7padWzSGXfOVJryU/8MK/O5b/XC1uM0OZIYsBy/8G6ngsJVhSEucAAAAASUVORK5CYII=" /> -->
        <i class="bx bxs-video-plus"  aria-hidden="true"></i>

    </a>
    <div class="dropdown-menu dropdown-menu-end">
        <a href="javascript:;">
            <div class="msg-header">
                <p class="msg-header-title">Online Teachers</p>
            </div>
        </a>
        <div style="overflow-y:auto; height:360px;">
            <div class="header-notifications-list" *ngFor="let item of onlineTeachers">
                <a class="dropdown-item" href="javascript:;" (click)="confirmationMessage(item)">

                    <div class="d-flex align-items-center">
                        <div class="notify"><i class="bx bx-group"></i>
                        </div>
                        <div>
                            <div class="flex-grow-1">Prof .{{item.name}}
                            </div>
                        </div>

                    </div>
                </a>

            </div>
        </div>

    </div>
</li>
<input type="button" class="d-none" id="recallTrainerList" (click)="isStudent()">
<app-call-confirmation-model *ngIf="IsConfirmation" [TeacherName]="TeacherName"
    (MessageStatusEvent)="MessageStatus($event)"></app-call-confirmation-model>