import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {environment} from './../../environments/environment'
import { constants } from '../constants';
// const url = environment.serviceUrl

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {
  url = environment.serviceUrl
  constructor(private httpClient: HttpClient) {
    if (location.hostname != "localhost")
    this.url = `${location.origin}/api/`;
   }


  upload(data,key) {

    let headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');
    // let options = new RequestOptions({ headers: headers });
    let apiUrl=constants[key]||key
    let uri = this.url+apiUrl;
    return this.httpClient.post<any>(uri, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
}
