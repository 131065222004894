import { Component, OnInit } from '@angular/core';
declare const MainSlider_owlCarousel: any;
@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.component.html',
  styleUrls: ['./main-slider.component.css']
})
export class MainSliderComponent implements OnInit {
  mainSliderList: Array<MSlider> = []
  constructor() {
    this.getMainSlider();
  }

  ngOnInit(): void {

    MainSlider_owlCarousel()

  }


  getMainSlider() {
    const sliders: Array<MSlider> = [
      {
        id: 1,
        image: 'assets/img/banner_img/banner-1.png',
        name: 'Make our Lifestyle,<br>Sustainable.',
        description: 'Enroll as a trainer now, it costs just a click.',
        name1: 'Explore Path',
      },
      {
        id: 2,
        image: 'assets/img/banner_img/banner-2.png',
        name: 'Adhere To Eco-friendly <br> Lifestyle by <br> Tracking our Habit.',
        description: 'Build yourself for a better tomorrow',
        name1: 'Explore Path',
      },
      {
        id: 3,
        image: 'assets/img/banner_img/banner-3.png',
        name: 'Share & Spread <br> The Eco Awareness.',
        description: 'Tailored tutoring for all your learning needs.',
        name1: 'Explore Path',
      },
      // {
      //   id: 4,
      //   image: 'assets/img/banner_img/banner4.png',
      //   name: 'Learn continually,<br>there&apos;s always<br>&ldquo;One more thing&rdquo;<br>to learn -Steve Jobs',
      //   description: 'Start now, register and explore for free',
      //   name1: 'Explore Path',
      // },
    ];
    this.mainSliderList = sliders
  }
}
interface MSlider {
  id: number;
  image: string;
  name: string;
  description: string;
  name1: string;
}

