import { Injectable } from '@angular/core';

declare const webkitSpeechRecognition: any;

@Injectable({ providedIn: 'root' })
export class VoiceRecognitionService {
    public speechMessage = '';
    recognition = new webkitSpeechRecognition();
    isStoppedSpeechRecog = false;
    tempWords: any;

    constructor() { }

    init() {
        try {
            this.recognition.interimResults = true;
            // this.recognition.lang = 'te';
            this.recognition.lang = 'en-US';

            this.recognition.addEventListener('result', (e: any) => {
                const transcript = Array.from(e.results)
                    .map((result: any) => result[0])
                    .map((result) => result.transcript)
                    .join('');
                this.tempWords = transcript;
                console.log(transcript);
            });

            // this.recognition.onerror = (event: any) => {
            //     this.stop();
            //     if (event.error === "no-speech") {
            //         alert("No speech was detected. Stopping...");
            //     } else if (event.error === "audio-capture") {
            //         alert("No microphone was found. Ensure that a microphone is installed.");
            //     } else if (event.error === "not-allowed") {
            //         alert("Permission to use microphone is blocked.");
            //     } else if (event.error === "aborted") {
            //         alert("Listening Stopped.");
            //     } else {
            //         alert("Error occurred in recognition: " + event.error);
            //     }
            // };
        } catch (error) {
            console.log(error);
        }
    }

    start() {
        this.isStoppedSpeechRecog = false;
        this.recognition.start();
        console.log("Speech recognition started");
        this.recognition.addEventListener('end', () => {
            if (this.isStoppedSpeechRecog) {
                this.recognition.stop();
                console.log("End speech recognition");
            } else {
                this.wordConcat();
                this.recognition.start();
            }
        });
    }

    stop() {
        this.isStoppedSpeechRecog = true;
        this.wordConcat();
        this.recognition.stop();
        console.log("End speech recognition");
    }

    wordConcat() {
        this.speechMessage += ` ${this.tempWords}.`;
        this.tempWords = '';
    }
}
