<!-- <div>
  <div style="margin-top:70px;">
    <div class="content-wrapper p-0"> -->

<!-- banner -->
<!-- <div class="container-fluid "> -->


<!-- <div class="row">
          <div class="col-xl-12">
            <img src="./assets/img/strip.png" class="img-fluid w-100" />
          </div>
        </div> -->
<!-- content -->


<!-- <div class="row">
          <div class="col-xl-8 pt-4">
            
            <div class="container-fluid" *ngIf="roleId==3">
              <div id="accordion" class="accordion">
                  <div class="card mb-0 active">
                      <div class="card-header collapsed accordion1" data-toggle="collapse" href="#collapseOne">
                          <a class="card-title">
                            My Enrolled Courses
                          </a>
                      </div>
                      <div id="collapseOne" class="card-body collapse show" data-parent="#accordion" >
                        <div *ngIf="course.classes&&course.classes.length">
                          <h5>Courses that you have enrolled for</h5>
                          <div class="table-responsive">
                          <table class="table table-striped table-responsive-md btn-table table-bordered text-dark">
                            <thead>
                              <tr>
                                <th> Name</th> -->
<!-- <th>Category</th> -->
<!-- <th>Course
                                  Content</th>
                                <th>Exam</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of course.classes">
                                <td>{{item.Name}}</td> -->
<!-- <td>Medical</td> -->
<!-- <td><a class="a_click" (click)="navigate(item,'HOME/learningmaterial')">view</a></td>
                                <td><a class="a_click" (click)="navigate(item,'HOME/postassessment')">Take Exam</a></td>
                              </tr>
                            </tbody>
                          </table>
                          </div>
                        </div>
                        <div class="text-dark" *ngIf="!course.classes|| !course.classes.length">
                          No records to Display
                        </div>
                      </div>
                  
                      <div class="card-header collapsed accordion1 mt-1" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                          <a class="card-title">
                            Online Sessions
                          </a>
                      </div>
                      <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" >
                        <div *ngIf="course.sessions&&course.sessions.length">
                          <h5>Online Sessions for today</h5>
                          <table class="table table-striped table-responsive-md btn-table text-dark">
                            <thead>
                              <tr>
                                <th> Session Name </th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Join</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of course.sessions">
                                <td>{{item.SessionName}}</td>
                                <td>{{item.StartTime}}</td>
                                <td>{{item.EndTime}}</td>
                                <td><a *ngIf='item.URL' (click)='joinSession(item)' >Join Session</a>
                                  <span *ngIf='!item.URL' >Host Not Joined</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div  class="text-dark" *ngIf="!course.sessions || !course.sessions.length">
                          No records Display
                        </div>
                      </div>
                  
                   
                  </div>
              </div>
          </div> -->

<!-- <div class="container-fluid" *ngIf="roleId!=2">

              <div class="accordion" id="accordionExample" >
                <div class="card active mb-1">
                  <div class="card-header p-1 cardheader-bg collapsed" data-toggle="collapse" id="headingOne">
                    <h2 class="mb-0">
                      <button class="btn btn-link p-1 btn-block text-white text-left" type="button"
                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        My Enrolled Courses
                      </button>
                    </h2>
                  </div>

                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div class="card-body p-3">

                      <div *ngIf="course.classes&&course.classes.length">
                        <h5>Courses that you have enrolled for</h5>
                        <table class="table table-striped table-responsive-md btn-table table-bordered">
                          <thead>
                            <tr>
                              <th> Name</th>
                            
                              <th>Course
                                Content</th>
                              <th>Exam</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of course.classes">
                              <td>{{item.Name}}</td>
                             <td>Medical</td>
                              <td><a class="a_click" (click)="navigate(item,'HOME/learningmaterial')">view</a></td>
                              <td><a class="a_click" (click)="navigate(item,'HOME/postassessment')">Take Exam</a></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div *ngIf="!course.classes|| !course.classes.length">
                        No records to Display
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div class="card active  mb-1">
                  <div class="card-header p-1 cardheader-bg collapsed" data-toggle="collapse" id="headingTwo">
                    <h2 class="mb-0">
                      <button class="btn btn-link p-1 btn-block text-white text-left" type="button"
                        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo">
                        Online Sessions
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                  
                    </div>
                  </div>
                </div>
                
              </div>
            </div> -->


<!-- </div> -->
<!-- <div class="col-xl-4  pt-4 pr-3">


            <div class="container ">
              <ul class="nav nav-tabs tabactive ml-3" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active tabactive" data-toggle="tab" href="#tabs-1" role="tab">Discussion</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">News</a>
                </li> -->

<!-- </ul> -->
<!-- Tab panes -->
<!-- <div class="tab-content bg-white shadow" style="border-radius:15px;">
                <div class="tab-pane    active p-2" id="tabs-1" role="tabpanel">
                  <div class="list list-hover" >
                    <ul [innerHTML]='data.ulDiscussions'></ul>
                  </div> -->
<!-- <div class="list list-hover">
          
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
   
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
           
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">Bravio Application</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By James</a>
                </div>
               
              </div>
            </div>
            
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
                
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
              
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">Quick Reports</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Ana</a>
                </div>
             
              </div>
            </div>
          
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
               
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
                
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
        
        
             
        
                <div> 
        
                
                </div>
           
              </div>
            </div>
       
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
           
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
                
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
              
              </div>
            </div>
            
                      <div class="list-item hoverable  p-lg-2 mb-0">
                        <div class="d-flex align-items-center">
                         
                          <div class="symbol symbol-40 symbol-light mr-4">
                            <span class="symbol-label bg-hover-white">
                              <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                            </span>
                          </div>
                   
                          <div class="d-flex flex-column flex-grow-1 mr-2">
                            <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                            <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                          </div>
                         
                        </div>
                      </div>
                         
            <div class="list-item hoverable  p-lg-2 mb-0">
              <div class="d-flex align-items-center">
          
                <div class="symbol symbol-40 symbol-light mr-4">
                  <span class="symbol-label bg-hover-white">
                    <img src="https://via.placeholder.com/30/09f/fff.png%20C/O%20https://placeholder.com/" class="h-50 align-self-center">
                  </span>
                </div>
               
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <span class="text-dark-75 font-size-h6 mb-0">CRM Reporting Tool</span>
                  <a href="#" class="text-muted text-hover-primary font-weight-bold">By Adam</a>
                </div>
        
              </div>
              
            
            </div>
        
        
            <div class="list-item hoverable pb-5 mb-0">
              <div class="float-right">
         <button class="btn btn-sm btn-primary">View More...</button>
              
              </div>
              
            
            </div>
           
          </div> -->

<!-- </div>
                <div class="tab-pane fade in p-2" id="tabs-2" role="tabpanel"> -->
<!-- <p>News</p> -->
<!-- <ul [innerHTML]='data.ulNews'></ul>
                </div>

              </div>


            </div>

          </div>


        </div>

      </div>



    </div> -->
<!-- content-wrapper ends -->
<!-- partial:partials/_footer.html -->

<!-- partial -->
<!-- </div>
</div> -->
















<div class="wrapper">
  <div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">

      <!----------------------- // Start Super Admin dashboard// -------------------------->
      <div class="container-fluid  pt-3" *ngIf="false">
        <!-- <div class="row">
          <div class="col-12">
            <div class="float-start">
              <form>
                <select class="form-select mb-3" aria-label=".form-select-lg example">
                  <option selected>Select Chart Type</option>
                  <option value="1">Bar</option>
                  <option value="2">line chart</option>
                  <option value="3">Pie chart</option>
                  <option value="3">Donut chart</option>
                  <option value="3">Radar chart</option>
                </select>
              </form>
            </div>
            <div class="float-start ms-3">
              <form>
                <select class="form-select mb-3" aria-label=".form-select-lg example">
                  <option selected>All</option>
                  <option value="1">Tenant1</option>
                  <option value="2">Tenant2</option>
                  <option value="3">Tenant3</option>
                  <option value="3">Tenant4</option>
                </select>
              </form>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_blue text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/staff.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Staff Attendance</h6>
                  </div>
                </div>
                <div class="my-3" id="chart1">

                  <div>
                    <div style="display: block">
                      <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [legend]="lineChartLegend"
                        [chartType]="lineChartType">
                      </canvas>
                    </div>
                  </div>
                </div>

                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_blue"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Present (80%)</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Absent (20%)</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_red text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/students.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Student Attendance</h6>
                  </div>
                </div>
                <div class="my-3" id="chart9">
                  <div>
                    <div style="display: block">
                      <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [legend]="lineChartLegend"
                        [chartType]="lineChartType">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_blue"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Present (80%)</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Absent (20%)</span></div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_orange text-white p-100"
                    style="justify-content: center; text-align: center"><img
                    src="../../assets/img/super-admin-images/inventory.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Total Fee</h6>
                  </div>
                </div>
                <div class="my-3" id="chart10">
                  <div>
                    <div style="display: block">
                      <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [legend]="lineChartLegend"
                        [chartType]="lineChartType">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div style="line-height: 2; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_blue"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Total Paid</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Total Due</span></div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_blue text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/class.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Classess</h6>
                  </div>
                </div>
                <div class="my-3" id="chart7">
                  <div>
                    <div style="display: block">
                      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions1"
                        [legend]="barChartLegend" [chartType]="barChartType">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_blue"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Online Classess (70)</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2"> No.of Offline Classess (30)</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_purple text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/bus.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Transport</h6>
                  </div>
                </div>
                <div class="my-3" id="chart7a">
                  <div>
                    <div style="display: block">
                      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions1"
                        [legend]="barChartLegend" [chartType]="barChartType">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row ">
                  <div class="col-6 card-values"><span class="text_purple"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Transport Opted (400)</span></div>
                  <div class="col-6 card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Transport Approved (340)</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_orange text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/inventory_1.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Inventory</h6>
                  </div>
                </div>
                <div class="my-3" id="chart7b">
                  <div>
                    <div style="display: block">
                      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions1"
                        [legend]="barChartLegend" [chartType]="barChartType">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_orange"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of items (30)</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2"> Quotations Appronved (22)</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body" >

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_blue1 text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/library.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Student Attendance</h6>
                  </div>
                </div>
                <div class="my-3" id="chart3a">
                  <div style="display: block" >
                    <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                      [chartType]="pieChartType"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_blue1"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Books (500)</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Fine amount (5000)</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body" >

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_blue1 text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/library.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Student Attendance</h6>
                  </div>
                </div>
                <div class="my-3" id="chart2a">
                  <div style="display: block" >
                    <canvas baseChart [data]="pieChartData1" [labels]="pieChartLabels1"
                      [chartType]="pieChartType1"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_blue1"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Student present</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Student Absent</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_red text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/fee.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Total Fee </h6>
                  </div>
                </div>
                <div class="my-3" id="chart2">
                  <div style="display: block" *ngIf="isPieChart">
                    <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
                      [chartType]="doughnutChartType"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_red"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Total Paid ({{paidAmount}})</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2"> Total Due ({{balance}})</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_green text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/course.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Courses</h6>
                  </div>
                </div>
                <div class="my-3" id="chart2b">
                  <div style="display: block">
                    <canvas baseChart [datasets]="radarChartData" [labels]="radarChartLabels"
                      [chartType]="radarChartType"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-6  card-values"><span class="text_green"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Active (22)</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">In active (2)</span></div>
                </div>

              </div>
            </div>
          </div>

        </div> -->
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_blue1 text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/staff.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Staff Attendance</h6>
                  </div>
                </div>
                <div class="my-3" id="chart3a">
                  <div style="display: block">
                    <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                      [chartType]="pieChartType"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row pt-4">
                  <div class="col-6  card-values"><span class="text_blue1"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Staff Present ({{staffPresent}})</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Staff Absent ({{staffAbsent}})</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_blue1 text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/students.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Student Attendance</h6>
                  </div>
                </div>
                <div class="my-3" id="chart2a">
                  <div style="display: block">
                    <canvas baseChart [data]="pieChartData1" [labels]="pieChartLabels1"
                      [chartType]="pieChartType1"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row pt-4">
                  <div class="col-6  card-values"><span class="text_blue1"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Student present ({{studentPresent}})</span></div>
                  <div class="col-6  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2">No.of Student Absent ({{studentAbsent}})</span></div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card radius-10 border-0 mb-4 overflow">
              <div class="card-body">

                <div class="d-flex align-items-center gap-3">
                  <div class="icon-box rounded p-2 shadow bg_red text-white"
                    style="justify-content: center; text-align: center"><img
                      src="../../assets/img/super-admin-images/fee.png" class="img-fluid" alt=""></div>
                  <div class="flex-grow-1">
                    <h6 class="mb-0 text_blue">Total Fee </h6>
                  </div>
                </div>
                <div class="my-3" id="chart2">
                  <div style="display: block" *ngIf="isPieChart">
                    <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
                      [chartType]="doughnutChartType"></canvas>
                  </div>
                </div>
                <div style="line-height: 2; font-weight: 500; font-size: 12px;" class="ps-0 mb-0 row">
                  <div class="col-4  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2"> Total Amount ({{totalAmount}})</span></div>
                  <div class="col-4  card-values"><span class="text_red"><i class="fas fa-circle"></i></span><span
                      class="ps-2">Total Paid ({{paidAmount}})</span></div>
                  <div class="col-4  card-values"><span class="text_yellow"><i class="fas fa-circle"></i></span><span
                      class="ps-2"> Total Due ({{balance}})</span></div>

                </div>

              </div>
            </div>
          </div>


        </div>
        <div class="row">

        </div>
        <div class="row">

        </div>



      </div>

      <div class="container-fluid  pt-3" *ngIf="roleId==4">


        <div class="row">

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_1">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Staff_Attendance.png">

              </div>
              <div class="card-body crd_1 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Staff Attendance(Day Wise)</h6>
                <div class="row">
                  <div class="col-7 mb-2">Present :</div>
                  <div class="col-5 mb-2"><b>{{staffPresent}}</b></div>
                  <div class="col-7 mb-2">Absent :</div>
                  <div class="col-5 mb-2"><b>{{staffAbsent}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_2">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Fee.png">

              </div>
              <div class="card-body crd_2 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Fees(Day Wise)</h6>
                <div class="row">
                  <div class="col-7 mb-2">Total Paid:</div>
                  <div class="col-5 mb-2"><b>{{paidAmount}}</b></div>
                  <div class="col-7 mb-2">Total Amount:</div>
                  <div class="col-5 mb-2"><b>{{totalAmount}}</b></div>
                  <div class="col-7 mb-2">Due Amount:</div>
                  <div class="col-5 mb-2"><b>{{balance}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_3">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Student_Attendance.png">

              </div>
              <div class="card-body crd_3 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Student Attendance(Day Wise)</h6>
                <div class="row">
                  <div class="col-7 mb-2">Present :</div>
                  <div class="col-5 mb-2"><b>{{studentPresent}}</b></div>
                  <div class="col-7 mb-2">Absent :</div>
                  <div class="col-5 mb-2"><b>{{studentAbsent}}</b></div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_1">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Staff_Attendance.png">

              </div>
              <div class="card-body crd_1 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Staff Attendance</h6>
                <div class="row">
                  <div class="col-7 mb-2">Present :</div>
                  <div class="col-5 mb-2"><b>{{staffAttendancePresent.staff_count}}</b></div>
                  <div class="col-7 mb-2">Absent :</div>
                  <div class="col-5 mb-2"><b>{{staffAttendanceAbsent.staff_count}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_2">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Fee.png">

              </div>
              <div class="card-body crd_2 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Fees</h6>
                <div class="row">
                  <div class="col-7 mb-2">Total Paid:</div>
                  <div class="col-5 mb-2"><b>{{studentfeereceivables.paid_amount}}</b></div>
                  <div class="col-7 mb-2">Total Amount:</div>
                  <div class="col-5 mb-2"><b>{{studentfeereceivables.total_amount}}</b></div>
                  <div class="col-7 mb-2">Due Amount:</div>
                  <div class="col-5 mb-2"><b>{{studentfeereceivables.balance}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_3">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Student_Attendance.png">

              </div>
              <div class="card-body crd_3 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Student Attendance</h6>
                <div class="row">
                  <div class="col-7 mb-2">Present :</div>
                  <div class="col-5 mb-2"><b>{{studentAttendancePresent.student_count}}</b></div>
                  <div class="col-7 mb-2">Absent :</div>
                  <div class="col-5 mb-2"><b>{{studentAttendanceAbsent.student_count}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_4">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Library.png">

              </div>
              <div class="card-body crd_4 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Library</h6>
                <div class="row">
                  <div class="col-7 mb-2">Total books:</div>
                  <div class="col-5 mb-2"><b>{{library.total_books}}</b></div>
                  <div class="col-7 mb-2">Books issued:</div>
                  <div class="col-5 mb-2"><b>{{library.books_issued}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_5">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Inventory.png">

              </div>
              <div class="card-body crd_5 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Inventory</h6>
                <div class="row">
                  <div class="col-7 mb-2">Total items:</div>
                  <div class="col-5 mb-2"><b>{{inventory.total_items}}</b></div>
                  <div class="col-7 mb-2">Items in use:</div>
                  <div class="col-5 mb-2"><b>{{inventory.items_used}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_6">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Feedback.png">

              </div>
              <div class="card-body crd_6 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Feedback</h6>
                <div class="row">
                  <div class="col-7 mb-2">Submission:</div>
                  <div class="col-5 mb-2"><b>{{feedback.submission}}</b></div>
                  <div class="col-7 mb-2">Non Submission:</div>
                  <div class="col-5 mb-2"><b>{{feedback.non_submission}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_7">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Users.png">

              </div>
              <div class="card-body crd_7 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Users</h6>
                <div class="row">
                  <div class="col-7 mb-2">Total Staff:</div>
                  <div class="col-5 mb-2"><b>{{users.total_staff}}</b></div>
                  <div class="col-7 mb-2">Students:</div>
                  <div class="col-5 mb-2"><b>{{users.students}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_8">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Events.png">

              </div>
              <div class="card-body crd_8 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Events</h6>
                <div class="row">
                  <div class="col-7 mb-2">Conducted:</div>
                  <div class="col-5 mb-2"><b>{{events.conducted}}</b></div>
                  <div class="col-7 mb-2">Registered:</div>
                  <div class="col-5 mb-2"><b>{{events.registered}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_1">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Courses.png">

              </div>
              <div class="card-body crd_1 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Courses</h6>
                <div class="row">
                  <div class="col-7 mb-2">Active:</div>
                  <div class="col-5 mb-2"><b>{{courses.active}}</b></div>
                  <div class="col-7 mb-2">Inactive:</div>
                  <div class="col-5 mb-2"><b>{{courses.in_active}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_2">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Total_Staff.png">

              </div>
              <div class="card-body crd_2 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Total Staff</h6>
                <div class="row">
                  <div class="col-7 mb-2">Active:</div>
                  <div class="col-5 mb-2"><b>{{totalStaff.active}}</b></div>
                  <div class="col-7 mb-2">Inactive:</div>
                  <div class="col-5 mb-2"><b>{{totalStaff.in_active}}</b></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-4 mb-4">
            <div class="card card-1 h-100 card-shadow">
              <div class="card-header border-0 p-0">
                <div class="half_circle circle_3">

                </div>
                <img class="icon_new_dashboard" src="../../assets/images/superAdmin-dashboard/Total_Students.png">

              </div>
              <div class="card-body crd_3 pt-4">
                <h6 class="pt-2 fw-bold mb-3">Total Students </h6>
                <div class="row">
                  <div class="col-7 mb-2">Registered:</div>
                  <div class="col-5 mb-2"><b>{{totalStudent.registered}}</b></div>
                  <div class="col-7 mb-2">Enrolled:</div>
                  <div class="col-5 mb-2"><b>{{totalStudent.enrolled}}</b></div>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div class="row d-none">

          <div class="col-xl-6 mb-4">
            <div class="card card-1  card-shadow">
              <div class="card-header border-0 pt-3 pb-3">
                <h5>Notice</h5>
              </div>

              <div class="card-body smooth-scroll  px-0 pt-0 style-1">
                <div class="bg-white pt-2 pb-2 ps-3 pe-3" *ngFor="let item of newsList">
                  <p class="title1 mb-1" data-bs-toggle="modal" data-bs-target="#newsModal"
                    (click)="getNews(item.EVENT_ID)"><a href="javascipt:;"
                      style="color: #5A5A5A">{{item.EVENT_NAME}}</a></p>
                  <p class="dt-time mb-0">{{item.EVENT_DESCRIPTION}}</p>
                </div>
              </div>

            </div>
          </div>
          <div class="col-xl-6 mb-4">
            <div class="card card-1 card-shadow">
              <div class="card-header border-0 pt-3 pb-3">
                <h5>Ongoing Sessions</h5>
              </div>
              <div class="card-body smooth-scroll  px-0 pt-0 style-1">

                <div class="bg-white pt-2 pb-2 ps-3 pe-3" *ngIf="course.sessions&&course.sessions.length">
                  <h5>Online Sessions for today</h5>
                  <!-- <p class="title1 mb-1">Class name with the extra length text will be </p> -->
                  <div class="clearfix" *ngFor="let item of course.sessions">
                    <div class="float-start">
                      <p class="dt-time mb-0"> {{item.SessionName}}</p>
                      <p class="dt-time mb-0"> {{item.StartTime}} </p>
                      <!-- <p class="dt-time mb-0"> {{item.EndTime}} </p> -->
                    </div>

                    <div class="float-end">
                      <button class="btn btn-sm btn-primary" (click)='joinSession(item)' *ngIf='item.URL'>Join
                        Class</button>
                      <span *ngIf='!item.URL'>Host Not Joined</span>
                      <span *ngIf="roleId==2 || roleId==2066">
                        <button class="btn btn-sm btn-primary" (click)='joinSession(item)' *ngIf='item.URL'>Start
                          Class</button>
                      </span>

                    </div>

                  </div>
                  <div class="text-dark" *ngIf="!course.sessions || !course.sessions.length">
                    No records Display
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


      </div>

      <!---------------------------End Super Admin dashboard-------------------------------->



      <!------------------------ // Start Admin dashboard// -------------------------------->

      <div class="container-fluid  pt-3" *ngIf="false">
        <div class="card rounded-0 border-0 p-xl-4">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-xl-12 col-12 mb-4">
                <div class="float-xl-end float-md-end"> 
                  <a href="" class="btn btn-sm btn-primary bg_blue"><i class="fas fa-check"></i> Verification</a>
                  <a href="" class="btn btn-sm btn-primary bg_blue"><i class="fas fa-plus"></i> Take Snap</a>
                </div>
              </div> 
            </div> -->
            <div class="row" *ngIf="false">
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_purple  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/requests.png" width="40px"
                        height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_purple w-100" style="color: #7460EE;">Requests</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_green  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/configuration.png"
                        width="40px" height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-light text_green w-100" style="color: #7ACE4C;">Configuration</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_light-pink  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/exam.png" width="40px"
                        height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_light-pink w-100" style="color: #FF7BAC;">Exam</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 " *ngIf="false">
                <div class="card h-100 text-center text-center bg_blue1  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/tutor.png" width="40px"
                        height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_blue1 w-100" style="color: #0097A7;">Tutor</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_yellow  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/leave-management.png"
                        width="40px" height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_yellow w-100" style="color: #FFE500;">Leave
                      Management</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_orange  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/timetable.png" width="40px"
                        height="40px" class="img-fluid" alt=""></div>
                    <a [routerLink]="['/HOME/time-table']" class="btn btn-sm btn-white text_orange w-100"
                      style="color: #FFBB44;">Time Table</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_blue  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/transport.png" width="40px"
                        height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_blue w-100" style="color: #11A0F8;">Transport</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_purple  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/library.png" width="40px"
                        height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_purple w-100" style="color: #7460EE;">Library</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6 mb-3 ">
                <div class="card h-100 text-center text-center bg_pink  p-3 text-white"
                  style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-3 rounded-circle"><img src="../../assets/img/admin-icons/business-and-finance.png"
                        width="40px" height="40px" class="img-fluid" alt=""></div>
                    <a href="" class="btn btn-sm btn-white text_pink w-100" style="color: #E75D8C;">Transactions</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid  pt-3 " *ngIf="roleId==1">
        <div class="card rounded-0 border-0 p-xl-4">
          <div class="card-body">
            <!-- <div class="row">
              <div class="col-xl-12 col-12 mb-4">
                <div class="float-xl-end float-md-end"> 
                  <a href="" class="btn btn-sm btn-primary bg_blue"><i class="fas fa-check"></i> Verification</a>
                  <a href="" class="btn btn-sm btn-primary bg_blue"><i class="fas fa-plus"></i> Take Snap</a>
                </div>
              </div> 
            </div> -->
            <div class="row">
              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-0 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Staff_Attendance.png"
                        width="50px" height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Staff Attendance(Day Wise)</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Present :</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{staffPresent}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Absent:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{staffAbsent}}</strong></p>
                      </div>



                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-2 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Fee.png" width="50px"
                        height="50px" class="img-fluid" alt=""></div>

                    <h6 class="mb-3">Fee(Day Wise)</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Total Amount :</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{totalAmount}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Total Paid:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{paidAmount}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Due Amount:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{balance}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-st h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Student_Attendance.png"
                        width="50px" height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Student Attendance(Day Wise)</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Present :</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{studentPresent}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Absent:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{studentAbsent}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-3 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Library.png" width="50px"
                        height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Library</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Total books:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{library.total_books}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Books issued:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{library.books_issued}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-5 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Inventory.png"
                        width="50px" height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Inventory</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Total items:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{inventory.total_items}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Items in use:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{inventory.items_used}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-6 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Feedback.png"
                        width="50px" height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Feedback</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Submission:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{feedback.submission}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Non Submission:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{feedback.non_submission}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-5 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Users.png" width="50px"
                        height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Users</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Total Staff:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{users.total_staff}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Students:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{users.students}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-6 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Events.png" width="50px"
                        height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Events</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Conducted:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{events.conducted}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Registered:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{events.registered}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column">
                <div class="card card-1 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Courses.png" width="50px"
                        height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Courses</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Active:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{courses.active}}</strong></p>
                      </div>
                      <!-- <div class="col-6">
                        <p>Inactive:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{courses.in_active}}</strong></p>
                      </div> -->



                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column d-none">
                <div class="card card-2 h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Total_Staff.png"
                        width="50px" height="50px" class="img-fluid" alt=""></div>

                    <h6 class="mb-3">Total Staff:</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Active:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{totalStaff.active}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Inactive:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{totalStaff.in_active}}</strong></p>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6  p-4 card_column">
                <div class="card card-st h-100  p-3" style="border-radius: .5rem; width: 100%;">
                  <div class="card-body p-0">
                    <div class=" p-2 rounded-bg"><img src="../../assets/images/admin-dashboard/Total_Students.png"
                        width="50px" height="50px" class="img-fluid" alt=""></div>
                    <h6 class="mb-3">Total Students</h6>
                    <div class="row">
                      <div class="col-6">
                        <p>Registered:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{totalStudent.registered}}</strong></p>
                      </div>
                      <div class="col-6">
                        <p>Enrolled:</p>
                      </div>
                      <div class="col-6">
                        <p><strong>{{totalStudent.enrolled}}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----------------------------End Admin dashboard-------------------------------------->





      <!-------------------------Start Trainer and Member dashboard--------------------------->

      <div class="container-fluid" *ngIf="roleId!=1 ">
        <div class="row">
          <div class="col-xl-9 pe-lg-2 ps-lg-1">


            <div class="pt-3 pb-3">

              <div class="mt-3">
                <h2 style="font-size:22px; color: #2B3674;">Hi {{userName}}</h2>
                <h2 style="font-size: 14px; color: #707EAE;">Welcome to SIP</h2>
              </div>
            </div>
            <div class="row d-none">

              <div class="col-xl-6 mb-4">
                <div class="card card-1  card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>News</h5>
                  </div>
                  <div class="card-body smooth-scroll  px-0 pt-0 style-1">

                    <div href="javascript:void(0)" class="bg-white pt-2 pb-2 ps-3 pe-3" *ngFor="let item of newsList">
                      <p class="title1 mb-1" data-bs-toggle="modal" data-bs-target="#newsModal"
                        (click)="getNews(item.EVENT_ID)"><a href="javascipt:;"
                          style="color: #5A5A5A">{{item.EVENT_NAME}}</a></p>
                      <p class="dt-time mb-0">{{item.EVENT_DESCRIPTION}}</p>

                    </div>


                  </div>
                </div>
              </div>



              <div class="col-xl-6 mb-4">
                <div class="card card-1 card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>Ongoing Sessions</h5>
                  </div>
                  <div class="card-body smooth-scroll  px-0 pt-0 style-1">

                    <div href="javascript:void(0)" class="bg-white pt-2 pb-2 ps-3 pe-3"
                      *ngIf="course.sessions&&course.sessions.length">
                      <h5>Online Sessions for today</h5>
                      <!-- <p class="title1 mb-1">Class name with the extra length text will be </p> -->
                      <div class="clearfix" *ngFor="let item of course.sessions">
                        <div class="float-start">
                          <p class="dt-time mb-0"> {{item.SessionName}}</p>
                          <p class="dt-time mb-0"> {{item.StartTime}} </p>
                          <!-- <p class="dt-time mb-0"> {{item.EndTime}} </p> -->
                        </div>

                        <div class="float-end">
                          <button class="btn btn-sm btn-primary" (click)='joinSession(item)' *ngIf='item.URL'>Join
                            Class</button>
                          <span *ngIf='!item.URL'>Host Not Joined</span>
                          <span *ngIf="roleId==2 || roleId==2066">
                            <button class="btn btn-sm btn-primary" (click)='joinSession(item)' *ngIf='item.URL'>Start
                              Class</button>
                          </span>

                        </div>

                      </div>
                      <div class="text-dark" *ngIf="!course.sessions || !course.sessions.length">
                        No records Display
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6" *ngIf="roleId==3">
                <div class="card text" style="width: 18rem;">
                  <div class="card-body">
                    <p class="card-title"><b>Mentor:</b>{{ecoMentorName}} </p>
                    <p class="card-title"><b>Mentor Email:</b><br>{{ecoMentorEmail}} </p>
                    <p class="card-title"><b>Mentor Mobile Number:</b>{{mentolmbl}} </p>

                    <p class="card-title"><b>Eco Comander:</b> {{ecoCommanderName}}</p>
                    <p class="card-title"><b>Eco Comander Email:</b><br> {{ecoCommanderEmail}}</p>
                    <p class="card-title"><b>Eco Comander Mobile Number:</b> {{ecoComanderMbl}}</p>
                    <!-- <p class="card-title"><b>Administrator:</b> {{administratorName}}</p>
                    <p class="card-title"><b>Administrator Email:</b><br> {{administratorEmail}}</p> -->
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card text" style="width: 18rem;" *ngFor="let item of meetings">
                  <div class="card-body">
                    <p class="card-title"><b>Batch Name:</b> {{item.batch_name}} </p>
                    <p class="card-title"><b>Subject:</b> {{item.subject_name}}</p>
                    <p class="card-title"><b>Start Date:</b> {{item.batchshd_startdate|date:'dd-MM-yyyy'}}, {{
                      item.batchshd_starttime.slice(0, 5) }}</p>
                    <!-- <p class="card-title"><b>End Date:</b> {{item.batchshd_enddate|date:'yyyy-MM-dd'}}, {{ item.batchshd_endtime.slice(0, 5) }}</p> -->

                  </div>
                  <div class="card-footer text-center">
                    <a href="javascript:" (click)="joinMeeting(item)" class="card-link">Join Link</a>

                  </div>
                </div>
              </div>

            </div>
            <div class="row pt-4">
              <div class="col-xl-12 mb-4" *ngIf="roleId==3">
                <div class="card card-1 card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>Quiz</h5>
                  </div>
                  <div class="card-body table-style px-0 pt-0">

                    <div class="bg-white pt-2 pb-2 ps-3 pe-3">

                      <div href="javascript:void(0)" class="clearfix" *ngFor="let item of course.classes">
                        <div class="float-start">
                          <p class="title1 mb-1">{{item.Name}} </p>
                          <!-- <p class="dt-time mb-0"> by Prof. Lakshmi Kumari kamaneni </p> -->

                        </div>

                        <div class="float-end">
                          <button class="btn btn-sm btn-outline-primary mt-3"
                            (click)="navigate(item,'HOME/components/learningmaterial')">View Files</button> &nbsp;
                          &nbsp;
                          <button class="btn btn-sm btn-primary mt-3"
                            (click)="navigate(item,'HOME/components/postassessment')">Start Test</button>
                        </div>

                      </div>
                    </div>

                    <!-- <div class="bg-white pt-2 pb-2 ps-3 pe-3">

                      <div class="clearfix">
                        <div class="float-start">
                          <p class="title1 mb-1">Class name with the extra length text will be </p>
                          <p class="dt-time mb-0"> by Prof. Lakshmi Kumari kamaneni </p>

                        </div>

                        <div class="float-end">
                          <button class="btn btn-sm btn-outline-primary mt-3">View Files</button> &nbsp; &nbsp;
                          <button class="btn btn-sm btn-primary mt-3">Start Test</button>
                        </div>

                      </div>
                    </div> -->









                    <div class="text-center">
                      <!-- <a href="#" style="text-decoration: none; color:#9C9C9D; font-size: 13px;">View all <i
                          class="bx bx-right-arrow-alt"></i></a> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 mb-4" *ngIf="roleId==3">
                <div class="card card-1 card-shadow">
                  <div class="card-header border-0 pt-3 pb-3">
                    <h5>Results</h5>
                  </div>
                  <div class="card-body table-style px-0 pt-0">

                    <div class="bg-white pt-2 pb-2 ps-3 pe-3">
                      <div *ngIf="results.length" class="table-responsive">
                        <table id="example" datatable class="display nowrap small display" style="width:100%">
                          <thead class="top" style="background-color: #37c1f0!important; color:#ffffff;">
                            <tr>
                              <th>Assessment Name</th>
                              <!-- <th>Assessment Type</th> -->
                              <!-- <th>Course Name</th> -->
                              <th>Written Date</th>
                              <th>Percentage Scored</th>
                              <!-- <th>Result</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of results; let i=index">
                              <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                              <!-- <td>{{item.ASSESSMENTTYPE}}</td> -->
                              <!-- <td>{{item.COURSE_NAME}}</td> -->
                              <td>{{item.ASSESSMENTDATE | date:'dd-MM-yyyyThh:mm:ss'}}</td>
                              <td>{{item.RESULT_PERCENTAGESCORED}}</td>
                              <!-- <td>{{item.RESULT}}</td> -->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div *ngIf="!results.length">
                        <p>No records to display</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="false">
              <div class="col-xl-12 mb-4">


                <div class="card card-1 card-shadow">
                  <div class="card-body table-style">
                    <nav class="tabs-style ">
                      <div class="float-start pt-2">
                        <h5>My Courses</h5>
                      </div>
                      <div class="nav nav-tabs border-0 float-end" id="nav-tab" role="tablist">
                        <!-- <button class="nav-link active border-0" id="nav-all-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
                          aria-selected="true">All</button> -->
                        <!-- <button class="nav-link border-0" id="nav-ongoing-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-ongoing" type="button" role="tab" aria-controls="nav-ongoing"
                          aria-selected="false">Ongoing</button> -->
                        <!-- <button class="nav-link border-0" type="button">Ongoing</button> -->
                        <!-- <button class="nav-link border-0" id="nav-meetings-tab" data-bs-toggle="tab"
                          data-bs-target="#nav-meetings" type="button" role="tab" aria-controls="nav-meetings"
                          aria-selected="false">Completed</button> -->

                      </div>
                    </nav>
                    <div class="tab-content mt-5" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab"
                        style="display: inline-block;">

                        <div class="table-responsive">


                          <table class="table table-borderless" style="width: 100%;">
                            <tbody>
                              <tr *ngFor="let item of cources">
                                <td class="pt-4">
                                  <span class="mt-2"
                                    style="background-color: #F6F8FD; border-radius: 50%; padding: 1rem;">
                                    <img src="" class="" />

                                  </span>

                                </td>
                                <td>
                                  <p class="" style="margin: 0px !important ;">{{item.COURSE_NAME}}</p>
                                  <!-- <span class="sub-title pt-0">Class</span> -->
                                </td>
                                <td class="pt-3" style="width:25%;">
                                  <div class="progress progress1">
                                    <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="90"
                                      aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </td>
                                <td class=" pt-3"><i class=""></i> <span></span></td>
                                <td class="btn-rounded-1 pt-2" style="width: 20%;">
                                  <button type="button" class="btn btn-outline-primary">Ongoing Course</button>
                                </td>
                              </tr>

                            </tbody>
                          </table>

                        </div>

                        <div class="float-end">
                          <!-- <a href="#" class="btn btn-sm"
                            style="text-decoration: none; color:#0066FF; font-size: 16px;font-weight:500; ">View all
                            <i class="bx bx-right-arrow-alt"></i></a> -->
                        </div>

                      </div>
                      <div class="tab-pane fade" id="nav-ongoing" role="tabpanel" aria-labelledby="nav-ongoing-tab">


                        45
                      </div>
                      <div class="tab-pane fade" id="nav-meetings" role="tabpanel" aria-labelledby="nav-meetings-tab">


                        89

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-xl-3 bg-white">
            <div>

              <div data-simplebar="true">

                <div style="background-color:#FAFAFA;border-radius: 20px;" class="p-1 mb-3 mt-3 ps-2 pe-2">


                  <div class="app">
                    <div class="app__main shadow-sm mt-3">
                      <div class="calendar">
                        <div id="calendar"></div>
                      </div>
                    </div>
                  </div>
                  <div class="upcoming mt-3" *ngIf="false">
                    <h6>Upcoming </h6>
                    <p>18-03-2022</p>

                    <ul>
                      <li>DBMS Assignment</li>
                      <li>Cyber Law class</li>
                    </ul>
                  </div>

                </div>

                <!-- <div class="card card-1 card-shadow right-cards mb-2">

                  <div class="card-body">
                    <div class="text-center">
                      <h6>What sessions would you like to prefer?</h6>
                      <div class="btn-rounded-1 mt-4">
                      <button type="button" class="btn btn-outline-primary px-4 py-1 me-2">Online</button>
                      <button type="button" class="btn btn-outline-primary px-4 py-1 " (click)="polls()">&nbsp; Take
                        Polls &nbsp;</button>
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- 
                <div class="card card-1 card-shadow right-cards mb-3">

                  <div class="card-body">
                    <div class="text-center">
                      <h6>What sessions would you like to prefer?</h6>
                      <div class="btn-rounded-1 mt-4">
                      <button type="button" class="btn btn-outline-primary px-4 py-1 " (click)="surveys()">Take
                        Surveys</button>
                      <button type="button" class="btn btn-outline-primary px-4 py-1">Offline</button>
                      </div>
                    </div>
                  </div>
                </div> -->


                <!-- <a href="#" class="btn btn-sm small"
                  style="text-decoration: none; color:#0066FF; font-size: 12px;font-weight:500; ">View all <i
                    class="bx bx-right-arrow-alt"></i></a> -->


              </div>
            </div>
          </div>
        </div>





      </div>

      <!-----------------------------End Trainer and Member dashboard ------------------------->
    </div>
  </div>
</div>




<!----------------------------------  Start News PopUp  --------------------------------->

<div class="modal fade" id="newsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header py-2">
        <h4 class=" modal-title w-100">news</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal">&times;</button>
        <!-- <h4 class="modal-title" >Upcoming Event</h4> -->
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
      </div>&nbsp;
      <div *ngIf='isNews'>
        <div class="container">
          <div class="row col-12">
            <div class="col-3">
              <img src="{{news.EVENT_IMAGE}}" alt="News">
            </div>
            <div class="col-9">
              <h3>{{news.EVENT_NAME}}</h3>
              <!-- <h5 class="description">{{news.EVENT_DESCRIPTION}}</h5> -->
              <P class="description">{{news.EVENT_DESCRIPTION}}</P>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        &nbsp;
        <button class="btn btn-danger" id='close' (click)="close()" data-bs-dismiss="modal" (click)="close()">
          Close</button>
      </div>
    </div>

  </div>
</div>

<!------------------------------- End Start News PopUp  --------------------------------->