import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatMessageDto, IUserInfo } from '../models/chat-message-dto';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  stopInterval: any;
  webSocket: WebSocket;
  chatNames = []
  chatObj = { org_id: '', doctor_id: '', beneficiary_id: null, consulation_id: '', message: '', usertype: '', patient_name: null, property: "register", doctor_name: "" };
  private chat_Messages = new BehaviorSubject<IUserInfo>(null);
  WebSocket_URL = environment.WebSocket_URL;
  private _chat_Messages$ = this.chat_Messages.asObservable();
  isLogOut: boolean = false;

  constructor(private toastr: ToastrService) {
  }

  getMessages(): Observable<IUserInfo> {
    return this._chat_Messages$;
  }

  setMessages(data: IUserInfo): void {
    this.chat_Messages.next(data);
  }

  connectSocket(orgId, docId, userType: string, userName: string) {
    try {
      const { RoleId = 0 } = sessionStorage;
      const uri = `${this.WebSocket_URL}telecon/chat`.replace('https', 'wss');
      this.webSocket = new WebSocket(uri);

      this.webSocket.onopen = (event) => {
        this.isLogOut = false;
        this.pingPongMessage();
        if (+RoleId == 2) {//Teacher
          this.chatObj = { org_id: orgId, doctor_id: docId, beneficiary_id: null, consulation_id: '', message: '', usertype: userType, patient_name: "", property: "register", doctor_name: userName };
          this.webSocket.send(JSON.stringify({ ...this.chatObj }));
        } else if (+RoleId == 3) {//Student
          this.chatObj = { org_id: orgId, doctor_id: null, beneficiary_id: docId, consulation_id: '', message: '', usertype: userType, patient_name: userName, property: "register", doctor_name: "" };
          this.webSocket.send(JSON.stringify({ ...this.chatObj }));
        }
      }

      this.webSocket.onmessage = (event) => {
        if (event.data != 'ping' && event.data != 'pong') {
          const chatMessageDto: ChatMessageDto = JSON.parse(event.data);
          if (chatMessageDto && chatMessageDto.message) {
            let userData: IUserInfo = {
              teacher_id: chatMessageDto.doctor_id,
              teacher_name: chatMessageDto.doctor_name,
              student_id: chatMessageDto.beneficiary_id,
              student_name: chatMessageDto.patient_name,
              usertype: chatMessageDto.usertype,
              message: chatMessageDto.message,
              curentDate: new Date()
            }

            this.setMessages(userData);
          }
        };
      }

      this.webSocket.onclose = (event) => {
        if (!this.isLogOut) {
          console.log('Close: ', event);
          clearInterval(this.stopInterval);
          const { RoleId = 0 } = sessionStorage;
          const { org_id, doctor_id, beneficiary_id, usertype, patient_name, doctor_name } = this.chatObj;

          if (+RoleId == 2)
            this.connectSocket(org_id, doctor_id, usertype, doctor_name);
          else if (+RoleId == 3)
            this.connectSocket(org_id, beneficiary_id, usertype, patient_name);
        }
      }
      this.webSocket.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      }
    } catch (error) {
      console.log(error);
    }

  }

  sendMessage(chatMessageDto: ChatMessageDto) {
    this.webSocket.send(JSON.stringify(chatMessageDto));
  }

  pingPongMessage() {
    this.stopInterval = setInterval(() => this.pingMsg(), 10000);
  }

  pingMsg() {
    if (this.webSocket && this.webSocket.readyState != 3) {
      this.webSocket.send('ping');
      // console.log('ping message for every 10 seconds');
    }
  }

  closeWebSocket() {
    this.webSocket?.close();
    this.stopInterval && clearInterval(this.stopInterval);
    this.isLogOut = true;
  }
}
