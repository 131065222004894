import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/pages/base.component';
import { CommonService } from 'src/app/services/common.service';
import { SamvaadUserRegister } from 'src/app/samvaad-user.component';
import { environment } from 'src/environments/environment';
import { FileuploadService } from '../../services/fileupload.service';
import * as moment from 'moment-timezone';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

declare let $: any;

@Component({
  selector: 'app-student-signup',
  templateUrl: './student-signup.component.html',
  styleUrls: ['./student-signup.component.css']
})
export class StudentSignupComponent extends BaseComponent implements OnInit {
  signUpForm: FormGroup;
  submitted: boolean = false;
  firstSlide: boolean = false;
  secondSlide: boolean = false;
  slide: boolean = true;
  slideOne: boolean = false;
  isOther: boolean = false;
  RegUser = new SamvaadUserRegister();
  samvaadUserPWD: string = '';
  RoleId = sessionStorage.getItem('RoleId');
  companyList: Array<any> = [];
  pay_Load: any = {};
  isLoginDetailsSubmitted: boolean = false;
  questionsList: Array<any> = [];
  backBtn: boolean = false;
  universities: Array<any> = [];
  addressStates: Array<any> = [];
  addressCity: Array<any> = [];
  selectedAnswers: any[] = [];
  inputFld: boolean = false;
  imgFIle: string = '';
  audioPath: string = '';
  regionId: any;
  stateId: any;
  selectedDate: string = '';
  typesOfFile: object = {
    'Uploaded Material': {
      types: ['doc', 'docx', 'pdf'],
      message: 'Please upload the',
    },
    'Audio': {
      types: ['mp3', 'wav'],
      message: "please upload the"
    },
    'Webinar Info': {
      types: ['m4v', 'avi', 'mpg', 'mp4'],
      message: "Please upload the "
    },
  };
  dropdownSettings: any = {}
  selectedItems: { item_id: number; item_text: string; }[];
  dropdownList: { item_id: number; item_text: string; }[] = []
  array: any[]; dates: any; month: any;
  formattedEndDate: string;
  time: number = 10 * 60;
  isNgoSelected: boolean = false;
  ngoTrustDescription: string = '';
  multiSelectOtherDescription: string = '';
  txtBox: boolean = false;
  nameOfEduDrp: boolean = false;
  inputFldforMulti: boolean = false;
  isChecked: boolean = false;

  constructor(private fb: FormBuilder, CommonService: CommonService,
    private route: Router, private FileuploadService: FileuploadService,
    toastr: ToastrService) {
    super(CommonService, toastr);



  }

  ngOnInit(): void {
    // this.loadUniversity();
    this.formInit();
    this.getState(this.stateId);
    this.loadQuestions();
    this.getRegDates();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };


  }
  handleEvent(event) {
    if (event.action == "done") {
      this.toastr.warning('Time Out');

      setTimeout(() => this.route.navigate(['tutorHome']), 200)
    }
  }
  formInit() {

    this.myForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.maxLength(100)]],
      mobileno: [''],
      username: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      dob: ['',],
      // address_details: [''],
      // availability_period: ['', Validators.required],
      whatsapp_number: ['', Validators.required],
      state_id: ['', Validators.required],
      // city: ['', Validators.required],
      name_of_university: ['',],
      // university_address: ['', Validators.required],
      university_pincode: ['', Validators.required],
      university_id: [''],
      courseid: [''],
      levelof_studydescription: [''],
      levelof_studyid: ['', Validators.required]
      // pincode: ['', Validators.required]
    })
    this.getFileUploadQuestion()
  }
  // async getState() {

  //   await this.CommonService.postCall('GetChildDictionary', { DictionaryID: 2 }).subscribe({
  //     next: (res: any) => {
  //       this.addressStates = res;
  //     },
  //     error: (err) => {
  //       console.log('addressStates error', err);
  //     },
  //     complete: () => { }
  //   }
  //   )
  // }
  getRegDates() {
    this.CommonService.postCall('CoursescheduleBatches/ChechRegStartandEnddate', { tnt_code: 87654321 }).subscribe((res: any) => {
      this.dates = res?.message;
      const endDate = new Date(res?.res?.registration_enddate);
      this.formattedEndDate = this.getMonthName(endDate.getMonth()) + ' ' + endDate.getFullYear();
      this.month = res.res?.registration_enddate;

    })
  }
  getMonthName(monthIndex: number): string {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return this.month = months[monthIndex];
  }
  keyPressAlphabetsSpe(event: any) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z(). ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  gotoRegistrationForm() {
    this.slide = false;
    this.slideOne = true;
  }
  dateOfBirth() {
    const today = new Date();
    const selectedDOB = new Date(this.selectedDate);
    const age = today.getFullYear() - selectedDOB.getFullYear();

    if (age < 18) {
      this.toastr.warning('You must be at least 18 years old.');
      this.selectedDate = '';
    }
  }

  getState(id: any) {
    this.stateId = id;
    this.addressStates = [];
    let payload = {

    }
    this.CommonService.postCall('Registration/LoadStetesandGroupRegion', payload).subscribe((res: any) => {
      this.addressStates = res;
      this.regionId = this.addressStates.find(e => e.state_id == id)?.region_id
    })
  }

  async onChangeState(value) {
    await this.CommonService.postCall('GetChildDictionary', { DictionaryID: this.addressStates?.find(m => m.DICTIONARYID == value).DICTIONARYID }).subscribe({
      next: (res: any) => {
        this.addressCity = res;
      },
      error: (err) => {
        console.log('addressCity error', err);
      },
      complete: () => { }
    }
    )
  }

  //Questions List APi
  loadQuestions() {
    this.CommonService.postCall('Registration/LoadQuestionsOptions', { 'TNT_CODE': 87654321 }).subscribe((res: any) => {
      this.questionsList = res;

      // Filter questions based on the question type (e.g., type 5)
      this.array = this.questionsList.filter((e: any) => e.REG_QUESTION_TYPE == 5);

      // Extract options from the filtered questions
      this.array.forEach(question => {
        if (question.dlc_registration_question_answer) {
          question.dlc_registration_question_answer.forEach(answer => {
            let obj = {
              item_id: answer.REG_QUESTION_ANSWER_ID,
              item_text: answer.REG_QUESTION_ANSWER_NAME
            };
            this.dropdownList.push(obj);
          });
        }
      });

    });
  }

  // University Dropdown APi
  loadUniversity(stateId: any) {
    this.universities = [];
    this.CommonService.postCall('UniversityMaster/GetUniversityByState', {
      "TNT_CODE": 87654321,
      "STATE_ID": stateId
    }).subscribe((res: any) => {
      this.universities = res;
    })
  }

  otherOption(event: any) {
    event.target?.value == '0' ? this.isOther = true : this.isOther = false;
  }
  changeSlide(request: string) {
    if (request == 'firstSlide') {
      this.firstSlide = true;
      this.slideOne = false;
      this.backBtn = false;
      this.loadQuestions();
    } else if (request == 'secondSlide') {
      this.secondSlide = true;
      this.firstSlide = false;
      this.slideOne = false;
      this.backBtn = false;
    }
  }

  // create registration Method
  save() {
    this.activeSpinner();
    let payload = this.myForm.getRawValue();
    const currentTimeIST = moment().tz('Asia/Kolkata');
    payload.TENANT_CODE = "87654321";
    payload.UserType = "26";
    payload.Role = "3";
    payload.Timezone = currentTimeIST.format();
    payload.CREATEDBY = "1";
    payload.dob = payload.dob == '' ? null : payload.dob;
    // payload.state_id=value.state_id
    this.CommonService.postCall('Registration/SaveRegistration', payload).subscribe((res: any) => {
      if (res.MESSAGE == "Registration Successful") {
        localStorage.setItem('userName', payload.username);
        this.changeSlide('firstSlide');
        this.firstSlide = true;
        this.backBtn = true;
        this.toastr.success(res.MESSAGE);
        this.deactivateSpinner();
        return
      } else {
        this.toastr.error(res.MESSAGE);
        this.firstSlide = false;
        this.slideOne = true;
        this.deactivateSpinner();
      }
    }, (e: any) => {
      this.toastr.error(e.error.MESSAGE);
      this.deactivateSpinner();
      // this.slide = true;
    })
  }
  previous(req: string, id: any) {
    if (req == 'back' && id == 1) {
      this.slide = true;
      this.slideOne = false;
      this.firstSlide = false;
      this.secondSlide = false;
    }
    else {
      this.slideOne = false;
      this.firstSlide = true;
      this.secondSlide = false;
    }
  }
  onItemSelect(e) { }
  onSelectAll(e) {

  }






  get f() { return this.signUpForm.controls; }

  selectedNgoOption(questionId: number, answerId: string, type: any, event: any) {
    const inputValue = event.target?.value;
    if (this.ngoTrustDescription != "" || this.ngoTrustDescription == undefined) {

      const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

      if (existingAnswerIndex !== -1) {
        this.selectedAnswers[existingAnswerIndex].SelectedOption = answerId;
        this.selectedAnswers[existingAnswerIndex].NGO_TRUST_DESCRIPTION = inputValue;

      } else {
        this.selectedAnswers.push({
          Type: type,
          REG_QUESTION_ID: questionId,
          SelectedOption: answerId.toString(),
          NGO_TRUST_DESCRIPTION: inputValue
        });
      }
    } else {
      const existingAnswerIndex = this.selectedAnswers.findIndex(answer => answer.REG_QUESTION_ID === questionId);
      if (existingAnswerIndex !== -1) {
        // Update the SelectedOption array with the selectedValues
        const selectedOptions = this.selectedAnswers[existingAnswerIndex].SelectedOption.split(',');
        const optionIndex = selectedOptions.indexOf(answerId);
        if (optionIndex === -1) {
          selectedOptions.push(answerId);
        } else {
          selectedOptions.splice(optionIndex, 1);
        }
        this.selectedAnswers[existingAnswerIndex].SelectedOption = selectedOptions.join(',');
      } else {
        // If an answer doesn't exist, add a new answer object to selectedAnswers array with the selectedValues
        this.selectedAnswers.push({
          Type: type,
          REG_QUESTION_ID: questionId,
          SelectedOption: answerId.toString(),
        });
      }
    }

  }
  // Function to handle user input for questions with options
  onAnswerSelected(questionId: number, answerId: string, otherOptn: string, type: any) {
    if (otherOptn == 'Other' || otherOptn === 'NGO/Trust') {
      this.inputFld = true;
      return
      // this.selectedNgoOption(questionId, answerId, type)
    } else if (otherOptn != 'NGO/Trust' && questionId == 8) {
      this.inputFld = false;
      this.ngoTrustDescription = '';
      this.selectedNgoOption(questionId, answerId, type, '')
      return
    } else {
      // Check if an answer for the question already exists in selectedAnswers
      const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);
      if (existingAnswerIndex !== -1) {
        delete this.selectedAnswers[existingAnswerIndex].NGO_TRUST_DESCRIPTION;
      }

      if (existingAnswerIndex !== -1) {
        this.selectedAnswers[existingAnswerIndex].SelectedOption = answerId;

      } else {
        this.selectedAnswers.push({
          Type: type,
          REG_QUESTION_ID: questionId,
          SelectedOption: answerId.toString(),
        });
      }
    }


  }


  // Function to handle user input for questions with no options
  onInputValueChanged(questionId: number, inputValue: string, type: any) {
    // Check if an answer for the question already exists in selectedAnswers
    const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

    if (existingAnswerIndex !== -1) {
      // If an answer exists, update it with the new input value
      this.selectedAnswers[existingAnswerIndex].SelectedOption = inputValue;
    } else {
      // If an answer doesn't exist, add a new answer object to selectedAnswers array
      this.selectedAnswers.push({ Type: type, REG_QUESTION_ID: questionId, SelectedOption: inputValue.toString() });
    }
  }
  // Function to handle checkbox selections for questions with options (REG_QUESTION_TYPE = 3)
  onCheckboxValueChanged(questionId: number, answerId: string, otherOptn: string, type: any, boolValue: boolean) {
    // if (otherOptn === 'Other' || otherOptn === 'NGO/Trust') {
    //   this.inputFld = true;
    // }

    // Check if an answer for the question already exists in selectedAnswers
    const existingAnswerIndex = this.selectedAnswers.findIndex(answer => answer.REG_QUESTION_ID === questionId);
    if (existingAnswerIndex !== -1) {
      // Update the SelectedOption array with the selectedValues
      const selectedOptions = this.selectedAnswers[existingAnswerIndex].SelectedOption.split(',');
      const optionIndex = selectedOptions.indexOf(answerId);

      if (boolValue) {
        // Checkbox is checked
        if (optionIndex === -1) {
          // Add the answerId to selectedOptions if it doesn't exist
          selectedOptions.push(answerId);
        }
      } else {
        // Checkbox is unchecked
        if (optionIndex === -1 && !boolValue) {
          // Remove the answerId if it exists
          selectedOptions.splice(optionIndex, 1);
        }
      }
      // Update the SelectedOption in selectedAnswers array
      this.selectedAnswers[existingAnswerIndex].SelectedOption = selectedOptions.join(',');
    } else {
      // If an answer doesn't exist, add a new answer object to selectedAnswers array with the selectedValues
      if (boolValue) {
        // Checkbox is checked
        this.selectedAnswers.push({
          Type: type,
          REG_QUESTION_ID: questionId,
          SelectedOption: answerId.toString(),
          // NGO_TRUST_DESCRIPTION: inputValue
        });
      }
    }
  }




  // Function to get the question type (1 for radio, 2 for input, 3 for checkbox)
  getQuestionType(questionId: number): number {
    const question = this.questionsList.find((item) => item.REG_QUESTION_ID === questionId);
    return question ? question.REG_QUESTION_TYPE : -1;
  }
  // Function to handle multiselect dropdown changes for questions with options (REG_QUESTION_TYPE = 5)
  onMultiselectValueChanged(questionId: number, answerId: string, type: any, selectedValues: any) {
    if (selectedValues?.length === 5) {
      const lastOptionSelected = selectedValues[selectedValues.length - 1];

      // If the last option is selected, remove it
      if (lastOptionSelected) {
        selectedValues.pop(); // Remove the last element

      }

    }

    const isOtherSelected = selectedValues?.some(option => option.item_text === 'Other:');

    // Update the input field visibility flag based on whether "Other:" is selected
    this.inputFldforMulti = isOtherSelected;
    if (this.inputFldforMulti === true) {
      this.selectedmultiDesc(questionId, answerId, type, '')
    } else {
      this.multiSelectOtherDescription = '';
      const selectedAnswer = this.selectedAnswers.find(answer => answer.FIELDOFINTEREST_OTHERDESCRIPTION);
      if (selectedAnswer) {
        selectedAnswer.FIELDOFINTEREST_OTHERDESCRIPTION = '';
      }
    }
    let result: any;
    result = selectedValues?.map(a => a?.item_id);

    const existingAnswerIndex = this.selectedAnswers.findIndex(answer => answer.REG_QUESTION_ID === questionId);

    if (existingAnswerIndex !== -1) {
      // If an answer exists, update the SelectedOption array with the selectedValues
      this.selectedAnswers[existingAnswerIndex].SelectedOption = result?.join(',');
    } else {
      // If an answer doesn't exist, add a new answer object to selectedAnswers array with the selectedValues
      this.selectedAnswers.push({
        Type: type,
        REG_QUESTION_ID: questionId,
        REG_QUESTION_TYPE: this.getQuestionType(questionId),
        SelectedOption: result?.join(',')
      });
    }
  }

  selectedmultiDesc(questionId: number, answerId: string, type: any, event: any) {
    const inputValue = event.target?.value;

    if (this.multiSelectOtherDescription != "") {

      const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

      if (existingAnswerIndex !== -1) {
        this.selectedAnswers[existingAnswerIndex].SelectedOption = answerId;
        this.selectedAnswers[existingAnswerIndex].FIELDOFINTEREST_OTHERDESCRIPTION = this.multiSelectOtherDescription;

      } else {
        this.selectedAnswers.push({
          Type: type,
          REG_QUESTION_ID: questionId,
          SelectedOption: answerId.toString(),
          FIELDOFINTEREST_OTHERDESCRIPTION: this.multiSelectOtherDescription
        });
      }
    } else {
      const existingAnswerIndex = this.selectedAnswers.findIndex((answer) => answer.REG_QUESTION_ID === questionId);

      if (existingAnswerIndex !== -1) {
        this.selectedAnswers[existingAnswerIndex].SelectedOption = answerId;

      } else {
        this.selectedAnswers.push({
          Type: type,
          REG_QUESTION_ID: questionId,
          SelectedOption: answerId.toString(),
        });
      }
    }
  }

  getFileUploadQuestion(): any | undefined {
    const questionId = 4;
    return this.questionsList.find(q => q.REG_QUESTION_ID === questionId);
  }

  onFileUploadChanged(event: any, questionId: number, req: any, type: any) {
    // const fileInput = event.target as HTMLInputElement;
    // const question = this.getFileUploadQuestion();

    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop();
      let materialType: object;

      if (req === '1') {
        materialType = this.typesOfFile['Uploaded Material'];
      } else if (req === '2') {
        // Check for both Audio and Video types
        // let audioType = this.typesOfFile['Audio'];
        let videoType = this.typesOfFile['Webinar Info'];
        // if (audioType && audioType['types'].includes(filetype.toLowerCase())) {
        //   materialType = audioType;
        // } 
        if (videoType && videoType['types'].includes(filetype.toLowerCase())) {
          materialType = videoType;
        } else {
          this.toastr.warning('Please upload video formats only');
          event.target.value = '';
          return;
        }
      }

      if (materialType) {
        let check = materialType['types'].includes(filetype.toLowerCase());
        if (check) {
          this.file = file;
          this.upload(questionId, req, type);
        } else {
          this.toastr.warning(materialType['message'] + JSON.stringify(materialType['types']));
          event.target.value = '';
          return;
        }
      } else {
        this.toastr.error('Invalid request type');
        return;
      }
    }


    // Perform the upload based on req, questionId, and type here
  }

  upload(questionId: number, req: any, type: any) {
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('DIRECTORY_NAME', 'upload_files_questions');
    this.activeSpinner();

    this.FileuploadService.upload(formData, 'Registration/Upload_File').pipe(
      finalize(() => {
        this.deactivateSpinner();
      })
    ).subscribe((res: any) => {
      try {
        this.fileName = res.path;
        this.selectedAnswers.push({ Type: type, REG_QUESTION_ID: questionId, SelectedOption: this.fileName });
        req == 1 ? this.imgFIle = this.fileName : this.audioPath = this.fileName;
        // this.filePath = this.fileName;
      } catch (e) {
        console.log(e);
      }
    }, err => {
      this.toastr.error(err.error ? err.error.message : "Video size should not be greater than 20 mb.");
    });
  }
  submitAnswers() {
    this.activeSpinner();
    let payload =
    {
      "UserName": localStorage.getItem('userName'),
      "TenantCode": 87654321,
      "dlc_userregistration_answer": this.selectedAnswers
    }
    for (const answer of this.selectedAnswers) {
      if (answer.REG_QUESTION_TYPE === 3 && answer.REG_QUESTION_TYPE === 5) {
        answer.SelectedOption = answer.SelectedOption.join(', ');
      }
    }
    // console.log(payload);
    // this.deactivateSpinner();
    // return
    // console.log(payload);
    // this.deactivateSpinner();
    // return
    // if (this.selectedAnswers.length >= 4) {
    this.CommonService.postCall('Registration/CreateUserSubmittedAnswers', payload).subscribe((res: any) => {
      this.toastr.success("Submited successful!");
      this.route.navigate(['tutorHome']);
      //  this.changeSlide('secondSlide');
      this.deactivateSpinner();
    }, (e: any) => {
      this.deactivateSpinner();
    })
    // }
    // else {
    //   this.deactivateSpinner();
    //   this.toastr.warning("Please enter all fields");
    //   return this.changeSlide('firstSlide');
    // }

  }

  audioSubmit() {
    if (this.fileName == '' || this.fileName == undefined) {
      return this.toastr.warning("Video File is mandatory!");
    }
    let payload = {
      "AUDIO_PATH": this.fileName,
      "UserName": localStorage.getItem('userName'),
      "TenantCode": 87654321
    }

    this.activeSpinner();
    this.CommonService.postCall('Registration/UpdateAudioFile', payload).subscribe((res: any) => {
      this.route.navigate(['tutorHome']);
      this.toastr.success("Submitted Successful!");
      this.deactivateSpinner();
    }, (e: any) => {
      this.toastr.error("Not Submitted!");
      this.deactivateSpinner();
    })
  }

  cancel() {
    this.route.navigate(['tutorHome']);
  }
  getTextbox(event: any) {
    if (event == '2' || event == '3') {
      this.txtBox = true;
      this.nameOfEduDrp = false;
    } else if (event == '1') {
      this.txtBox = false;
      this.nameOfEduDrp = true;
    }
  }
}