import { Component, OnInit } from '@angular/core';

declare const LoadTextToSpeech: any;
@Component({
  selector: 'app-text-to-speech',
  templateUrl: './text-to-speech.component.html',
  styleUrls: ['./text-to-speech.component.css']
})
export class TextToSpeechComponent implements OnInit {
  message: string = "";
  ngOnInit(): void {
    LoadTextToSpeech();
  }
  
  back() {
    window.history.back();
  }

  /*
  textarea: any = document.querySelector("textarea");
  voiceList: any = document.querySelector("select");
  speechBtn: any = document.querySelector("button");

  synth = speechSynthesis;
  isSpeaking = true;

  ngOnInit(): void {
    this.voices();
    setTimeout(() =>this.synth.addEventListener("voiceschanged", this.voices), 1000);
  }

  voices() {
    for (let voice of this.synth.getVoices()) {
      let selected = voice.name === "Google US English" ? "selected" : "";
      let option = `<option value="${voice.name}" ${selected}>${voice.name} (${voice.lang})</option>`;
      this.voiceList.insertAdjacentHTML("beforeend", option);
    }
  }

  textToSpeech(text: any) {
    let utterance = new SpeechSynthesisUtterance(text);
    for (let voice of this.synth.getVoices()) {
      if (voice.name === this.voiceList.value) {
        utterance.voice = voice;
      }
    }
    this.synth.speak(utterance);
  }

  TextToSpeech(e: any) {
    e.preventDefault();
    if (this.textarea.value !== "") {
      if (!this.synth.speaking) {
        this.textToSpeech(this.textarea.value);
      }
      if (this.textarea.value.length > 80) {
        setInterval(() => {
          if (!this.synth.speaking && !this.isSpeaking) {
            this.isSpeaking = true;
            this.speechBtn.innerText = "Convert To Speech";
          } else {
          }
        }, 500);
        if (this.isSpeaking) {
          this.synth.resume();
          this.isSpeaking = false;
          this.speechBtn.innerText = "Pause Speech";
        } else {
          this.synth.pause();
          this.isSpeaking = true;
          this.speechBtn.innerText = "Resume Speech";
        }
      } else {
        this.speechBtn.innerText = "Convert To Speech";
      }
    }
  }
  */
}
