import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

declare let $: any;
declare let stopVideo: any;
@Component({
  selector: 'app-video-room',
  templateUrl: './video-room.component.html',
  styleUrls: ['./video-room.component.css']
})
export class VideoRoomComponent implements OnInit {

  constructor(private toastr: ToastrService) { }

  ngOnInit(): void {
    //this.leftMenu();
  }

  leftMenu() {
    $(document).ready(function () {
      $(".drag").draggable({ containment: "window", scroll: true, });
    });
  }

  gotoConsultation() {
    stopVideo();
  }
  closeVideoConference() {
    document.getElementById('navLeaveButton')?.click();
  }
}
