export class ChatMessageDto {

    org_id: string;
    doctor_id: string;
    beneficiary_id: string;
    consulation_id: string;
    message: string;
    usertype: string;
    patient_name: string;
    property: string;
    doctor_name: string;

    constructor(org_id: string, doctor_id: string, beneficiary_id: string, consulation_id: string, message: string, userType: string, patient_name: string, property: string, doctor_name: string) {
        this.org_id = org_id;
        this.property = property;
        this.doctor_id = doctor_id;
        this.beneficiary_id = beneficiary_id;
        this.consulation_id = consulation_id;
        this.message = message;
        this.usertype = userType;
        this.patient_name = patient_name;
        this.doctor_name = doctor_name;
    }
}
export interface IUserInfo {
    student_id: string;
    student_name: string;
    teacher_id: string;
    teacher_name: string;
    usertype: string;
    message: string;
    curentDate: Date;
}