<section class="about-bg" style="background-color: #98ca3c;">
    <div class="container pt-5 pb-5">
        <div class="row pt-3 ">
            <div class="col-xl-6 mb-4 ">

                <div class="card" style="background-color: transparent; border: 0;">
                    <div class="card-body p-0">
                        <div class="embed-responsive embed-responsive-16by9">
                            <!-- <iframe class="embed-responsive-item" src="..."></iframe> -->

                            <!-- <iframe width="100%" height="300px" style="border-radius: 15px" src="https://www.youtube.com/embed/7X8II6J-6mU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                            <img src="./../../../assets/img/banner_img/Group-1.png" style="border-radius: 15px" class="img-fluid">
                        </div>
                        <!-- <div class="video-caption p-3"><h5 class="mb-0"><b>Educational Technology</b></h5><i class="fa-solid fa-location-dot"></i> Jakartha, Indonesia</div> -->
                    </div>
                </div>
                <!-- <img src="img/about-img.png" class="img-fluid mx-xl-0 mx-auto d-block"> -->

            </div>
            <div class="col-xl-6 d-flex align-items-center mb-4">
                <div class="p-xl-0 p-3">
                    <h2 style="font-weight:700; margin-bottom: 15px;" class="text-white">
                        Eco Mitram - Paryavaran Sanrakshan
                    </h2>
                    <p class="samvaad-txt mb-4">
                        What do we do? <br>
                        <br>
                        UPKRAM
                        <br>
                        Save Water
                        - Polythene Free
                        - Plantation
                        <br>
                        <br>
                        How do we do?
                        <br>
                        <br>
                        KARYAVIBHAG
                        <br>
                        - Educational Institutes Karyavibhag 
                        - NGO Karyavibhag <br>
                        - NariShakti Karyavibhag
                        - Religious Institutes Karyavibhag 
                    </p>
                    <!-- <button class="btn btn-about py-2 px-3" (click)="gotoSignUp()" type="button">Become a Learner, Trainer and Creator</button>
                     -->

                    <button class="btn btn-blue " (click)="gotoSignUp()" type="button">Become a part of SIP</button>
                </div>

            </div>
        </div>

    </div>
</section>