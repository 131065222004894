<input type="hidden" id="fromId">
<input type="hidden" id="toId">
<input type="hidden" id="log">
<input type="hidden" id="sessionName" value="">
<input type="hidden" id="user" value="">
<input type="hidden" id="forceRecordingId">
<input type="hidden" id="ovUrl">
<input type="hidden" id="ovKey">
<input type="hidden" id="apUrl">
<input type="hidden" id="newUserId">
<div id="blackout" class="noselect" style.display = "{{isConnectionAvailable ? 'none' : 'block'}}">
  <br /><br /><br /><br /><br /><p>No Internet connection!</p><br />
</div>
<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Please Wait... </p>
</ngx-spinner>
<!-- <div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div> -->
<button type="button" style="display: none;" id="docnameshow" (click)="sourcetype()"></button>
<button type="button" style="display: none;" id="docnameshownew" (click)="sourcetypeclick()"></button>
<button type="button" style="display: none;" id="videoCallModal" data-bs-toggle="modal"
  data-bs-target="#exampleModalCenter"></button>
<div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="exampleModalCenter" tabindex="-1"
  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
    <div class="modal-content panelbg">
      <div class="modal-body">
        <h5>Video Conference Confirmation</h5>
        <div class="row">
          <div class="col-md-5 col-6 pr-0">
            <div class="message">
              <h6>{{sourcetitle}} <span class="text-primary" id="patient_name"></span> is calling <span
                  id="patient_complaints"></span>
              </h6>
            </div>
          </div>
          <div class="col-md-1 col-6 pr-0"></div>
          <div class="col-md-6 col-6 pt-2 pl-0">
            <h6>Hello Prof. <span class="text-primary" id="teacherName"></span></h6>
            <p class="small">You have an incoming call, Please accept</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success rounded-circle btn-lg ml-1 mr-1 call-animation"
          data-bs-dismiss="modal" (click)="acceptCall()"> <i class="fa fa-phone text-white"></i></button>
        <button type="button" onclick="cancelCall()" class="btn btn-danger rounded-circle btn-lg ml-1 mr-1"
          data-bs-dismiss="modal">
          <i class="fa fa-phone fa-rotate-180 text-white "></i><br /></button>
        <button type="button" style="display: none;" id="btnVideoCallModalClose" data-bs-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>
<audio id="audio">
  <source src="/assets/openvidu/ringtone.mp3" type="audio/mpeg">
</audio>
<div id="main-content">
  <router-outlet></router-outlet>
</div>
<button type="button" style="display: none;" (click)="declineCall()" id="btnDeclineCall"></button>
<button type="button" style="display: none;" (click)="viduException()" id="btnException"></button>

<button type="button" style="display: none;" id="recStart" (click)="onRec(true)"></button>
<button type="button" style="display: none;" id="btnPatient_connection" (click)="onPatient_connection"></button>

<app-video-room *ngIf="isShowVideo"></app-video-room>

<!-- Button trigger modal for Studen calling to Teacher -->
<button type="button" class="d-none" id="btnStudentCallToTeacherModel" data-bs-toggle="modal"
  data-bs-target="#StudentCallToTeacherModel">
</button>

<!-- Modal -->
<div class="modal fade" id="StudentCallToTeacherModel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Initiating Call..</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          onclick="cancelCall()"></button>
      </div>
      <div class="modal-body">
        <span id="callToTeacherMessage"></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" id="btnCloseCallToTeacherMessage" onclick="cancelCall()"
          data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn d-none" id="btnCloseCallToTeacherMessage1"
          data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>