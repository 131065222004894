<div class="limiter">
    <div class="container-login100"
        style="background-image: url('../../../assets/images/dhanush/bg-image.png');height: 100vh; overflow: auto; background-size: cover; background-repeat: no-repeat;">
        <div class="wrap-login100">
            <!-- <div class="text-center"> <img id="homepageimage_path" src="../../../../assets/img/samvaad_tutor_logo.png" [routerLink]="['']"
                    style="cursor:pointer;" class="img-fluid" />
            </div> -->
            <br>

            <div class="container-fluid container-login100">
                <div class="row">
                    <div class="col-xl-8 mx-xl-auto px-4 py-5">

                        <div class="card card1 mb-2">
                            <div class="card-header text-center">
                                <h5 class="mb-0">Social Internship Program Application</h5>
                                <!-- <h5 class="float-end">
                                    <countdown *ngIf="time" [config]="{leftTime: time}" (event)="handleEvent($event)">
                                    </countdown>
                                </h5> -->
                            </div>
                            <div class="card-body px-4">
                                <div class="row">
                                    <div class="col-12" *ngIf="dates==true">

                                        <p class="mb-0">The program consists of 4 online sessions, 10 tasks and
                                            submission of the tasks by 7th of the following month.</p>
                                        <p class="mb-0">Certification will strictly depend on the merits.</p>
                                        <p class="mb-3">Late submission of tasks will have an effect on the merits.</p>

                                        <!-- <p>mounika.123@gmail.com <a href="#">Switch accounts</a></p> -->

                                        <!-- <p>The name and photo associated with your Google Account will be recorded when
                                            you upload files and submit this form. Only the email address you enter is
                                            part of your response.</p> -->
                                        <p class="text-danger mb-0">* Indicates required question</p>
                                    </div>
                                    <div class="col-12" *ngIf="dates!=true">

                                        <h6 class="mb-0">The registration period for Month <b>{{month}}</b> is over.
                                            Please come back
                                            later.</h6>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div *ngIf="dates==true">
                            <div class="card card1 mb-3" *ngIf="slide">
                                <div class="card-body px-4 text-center">
                                    <img src="../../../assets/img/eco mitram logo/eco-background.jpeg" class="img-fluid"
                                        alt="">
                                </div>
                                <div class="row pb-2">
                                    <div class="col-12">

                                        <button class="btn btn-sm btn-primary float-end px-3 ms-3"
                                            (click)="gotoRegistrationForm()">
                                            Next</button>
                                        <button class="btn btn-sm btn-danger float-end px-3 ms-3"
                                            (click)="cancel()">Cancel</button>
                                        <!-- [disabled]="myForm.invalid -->
                                    </div>
                                </div>
                            </div>
                            <div class="card card1 mb-3" *ngIf="slideOne">
                                <div class="card-body px-4">
                                    <form action="" [formGroup]="myForm" (ngOnSubmit)="save()">
                                        <div class="row">
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">

                                                <label for="textfiled1" class="form-label mb-1">Full Name of
                                                    Participant/प्रतिभागी का नाम <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="firstname"
                                                    (keypress)="keyPressAlphabetsSpe($event)">
                                            </div>
                                            <!-- <div class="col-xl-6 col-md-6  col-12 mb-3 d-none">
                                                <label for="textfiled1" class="form-label mb-1">Availability for the
                                                    period/अवधिके लिए उपलब्धता <span
                                                        class="text-danger">*</span></label>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" id="inlineCheckbox1"
                                                        value="true" formControlName="availability_period">
                                                    <label class="form-check-label"
                                                        for="inlineCheckbox1">Yes/हाँ</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" id="inlineCheckbox2"
                                                        value="false" formControlName="availability_period">
                                                    <label class="form-check-label"
                                                        for="inlineCheckbox2">No/नहीं</label>
                                                </div>
                                            </div> -->
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">Email
                                                    <span class="text-danger">*</span></label>
                                                <input type="email" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="username">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">Date of birth/जन्म तिथि
                                                </label>
                                                <input type="date" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="dob"
                                                    [(ngModel)]="selectedDate" (change)="dateOfBirth()">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">Mobile Number </label>
                                                <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="mobileno"
                                                    maxlength="12"
                                                    oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">WhatsApp Number <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="whatsapp_number"
                                                    maxlength="12"
                                                    oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">Level of study <span
                                                        class="text-danger">*</span></label>
                                                <select (change)="getTextbox($any($event.target).value)"
                                                    formControlName="levelof_studyid"
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" selected>Select</option>
                                                    <option value="1">University</option>
                                                    <option value="2">College</option>
                                                    <option value="3">School</option>

                                                </select>
                                            </div>

                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">State Of
                                                    University/College/School<span class="text-danger">*</span></label>
                                                <select formControlName='state_id'
                                                    (change)="getState($event.target.value);loadUniversity($event.target.value)"
                                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                                    <option value="" selected>Select State</option>
                                                    <option *ngFor='let item of addressStates' [value]='item.state_id'>
                                                        {{item.state_name}}</option>
                                                </select>
                                                <!-- <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp" formControlName="state"> -->
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3" *ngIf="txtBox">
                                                <label for="textfiled1" class="form-label mb-1"></label>
                                                <input type="text" class="form-control form-control-sm"
                                                    placeholder="Enter your educational institute"
                                                    formControlName="levelof_studydescription">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3" *ngIf="nameOfEduDrp">
                                                <label for="textfiled1" class="form-label mb-1">Name of Educational
                                                    Institution <span class="text-danger">*</span></label>
                                                <select class="form-control form-control-sm shadow-sm rounded-sm"
                                                    (change)="otherOption($event)" formControlName="university_id">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let item of  universities"
                                                        [value]="item.UNIVERSITY_ID">
                                                        {{item.UNIVERSITY_NAME}}</option>
                                                    <option value="0">Any Other</option>
                                                </select>
                                                <!-- <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp" formControlName="name_of_university"> -->
                                            </div>

                                            <!-- <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">University Address <span
                                                        class="text-danger">*</span></label>
                                                <input type="textarea" class="form-control form-control-sm"
                                                    id="textfiled1" aria-describedby="emailHelp"
                                                    formControlName="university_address">
                                            </div> -->
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">Pin Code Of
                                                    University<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="university_pincode"
                                                    maxlength="6"
                                                    oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3" *ngIf="isOther">
                                                <label for="textfiled1" class="form-label mb-1">Name of your
                                                    institution(if
                                                    selected any other)<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="name_of_university">
                                            </div>
                                            <div class="col-xl-6 col-md-6 col-12 mb-3">
                                                <label for="textfiled1" class="form-label mb-1">What course are you
                                                    pursuing
                                                </label>
                                                <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                    aria-describedby="emailHelp" formControlName="courseid"
                                                    (keypress)="keyPressAlphabetsSpe($event)">
                                            </div>


                                            <!-- <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp" formControlName="city"> -->
                                            <!-- </div> -->


                                            <!-- <div class="col-xl-6 col-md-6 col-12 mb-3">
                                            <label for="textfiled1" class="form-label mb-1">District <span
                                                    class="text-danger">*</span></label>
                                            <select formControlName='city'
                                                class="form-select form-select-sm shadow-sm rounded-lg">
                                                <option value="" selected>Select City</option>
                                                <option *ngFor='let item of addressCity' [value]='item.DICTIONARYID'>
                                                    {{item.DICTIONARYNAME}}</option>
                                            </select> -->
                                            <!-- <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp" formControlName="city"> -->
                                            <!-- </div> -->

                                            <!-- <div class="col-xl-6 col-md-6 col-12 mb-3">
                                            <label for="textfiled1" class="form-label mb-1">Permanent Address <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp" formControlName="address_details">
                                        </div>
                                        <div class="col-xl-6 col-md-6 col-12 mb-3">
                                            <label for="textfiled1" class="form-label mb-1">Pin Code <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp" formControlName="pincode" maxlength="6"
                                                oninput="this.value = this.value.replace(/[^0-9]/g, '');">
                                        </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <!-- <button class="btn btn-sm btn-danger float-end px-3 ms-3"
                                                    (click)="back()">Back</button> -->
                                                <button class="btn btn-sm btn-danger float-end px-3 ms-3"
                                                    (click)="previous('back',1)">Back</button>
                                                <button class="btn btn-sm btn-primary float-end px-3 ms-3"
                                                    [disabled]="myForm.invalid" (click)="save(myForm)">Save &
                                                    Next</button>
                                                <!-- [disabled]="myForm.invalid -->
                                            </div>
                                        </div>
                                    </form>



                                </div>
                            </div>
                            <!-- *ngIf="firstSlide" -->
                            <div class="card card1 mb-3" *ngIf="firstSlide">
                                <div class="card-body px-4">
                                    <div class="row" *ngFor="let item of questionsList;let i=index">
                                        <div class="col-xl-12 col-12 mb-3">
                                            <label for="textfiled1" class="form-label mb-1">
                                                {{i+1}} {{item.REG_QUESTION_NAME}}
                                                <!-- Do you have any exams in the
                                            upcoming month?/क्या आने वाले महीने में आपकी कोई परीक्षा है?  <span class="text-danger">*</span>-->
                                            </label><br />
                                            <ng-container *ngIf="item.REG_QUESTION_TYPE === 1">
                                                <ng-container
                                                    *ngFor="let ques of item?.dlc_registration_question_answer">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio"
                                                            [id]="'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID"
                                                            [name]="'question' + item.REG_QUESTION_ID"
                                                            [value]="ques.REG_QUESTION_ANSWER_ID"
                                                            [checked]="ques.REG_QUESTION_ANSWER_ID === ques.SelectedOption"
                                                            (change)="onAnswerSelected(item.REG_QUESTION_ID, ques.REG_QUESTION_ANSWER_ID,ques?.REG_QUESTION_ANSWER_NAME,item.REG_QUESTION_TYPE)">
                                                        <input type="text"
                                                            *ngIf="inputFld && ques.REG_QUESTION_ANSWER_NAME === 'NGO/Trust'"
                                                            [(ngModel)]="ngoTrustDescription"
                                                            (input)="selectedNgoOption(item.REG_QUESTION_ID, ques.REG_QUESTION_ANSWER_ID,item.REG_QUESTION_TYPE,$event)">
                                                        <label class="form-check-label"
                                                            [for]="'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID">{{ques?.REG_QUESTION_ANSWER_NAME}}</label>
                                                    </div>

                                                </ng-container>

                                            </ng-container>

                                            <ng-container *ngIf="item.REG_QUESTION_TYPE === 2">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control form-control-sm"
                                                            id="textfiled1" aria-describedby="emailHelp"
                                                            (input)="onInputValueChanged(item.REG_QUESTION_ID, $event.target.value,item.REG_QUESTION_TYPE)">

                                                    </div>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="item.REG_QUESTION_TYPE === 3">
                                                <!-- For questions with checkboxes (REG_QUESTION_TYPE = 3) -->
                                                <ng-container
                                                    *ngFor="let ques of item?.dlc_registration_question_answer">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            [id]="'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID"
                                                            [name]="'question' + item.REG_QUESTION_ID" 
                                                            [value]="ques.REG_QUESTION_ANSWER_ID"
                                                            [checked]="ques.REG_QUESTION_ANSWER_ID === ques.SelectedOption"
                                                            (change)="onCheckboxValueChanged(item.REG_QUESTION_ID,  ques.REG_QUESTION_ANSWER_ID,ques?.REG_QUESTION_ANSWER_NAME,item.REG_QUESTION_TYPE,$event.target.checked)">
                                                            <input type="text"
                                                            *ngIf="inputFld && ques.REG_QUESTION_ANSWER_NAME === 'NGO/Trust'"
                                                            [(ngModel)]="ngoTrustDescription"
                                                            (input)="selectedNgoOption(item.REG_QUESTION_ID, ques.REG_QUESTION_ANSWER_ID,item.REG_QUESTION_TYPE,$event)">
                                                        <label class="form-check-label"
                                                            [for]="'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID">{{ques?.REG_QUESTION_ANSWER_NAME}}</label>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="item.REG_QUESTION_TYPE === 5">
                                                <ng-container>
                                                    <ng-multiselect-dropdown style="width: 98%" [placeholder]="'select'"
                                                        [settings]="dropdownSettings" [data]="dropdownList"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (ngModelChange)="onMultiselectValueChanged(item.REG_QUESTION_ID, '', item.REG_QUESTION_TYPE, $event)"
                                                        [(ngModel)]='selectedItems' (onSelectAll)="onSelectAll($event)">
                                                    </ng-multiselect-dropdown>
                                                    <div class="col-md-6 pt-2">
                                                        <input type="text" class="form-control form-control-sm"
                                                            *ngIf="inputFldforMulti"
                                                            [(ngModel)]="multiSelectOtherDescription"
                                                            (input)="selectedmultiDesc(item.REG_QUESTION_ID, item.dlc_registration_question_answer[4]?.REG_QUESTION_ANSWER_ID,item.REG_QUESTION_TYPE,$event)">
                                                    </div>
                                                </ng-container>

                                            </ng-container>


                                            <!-- Question ID 4: File Upload -->
                                            <!-- <ng-container *ngIf="item.REG_QUESTION_TYPE === 4">
                                            <input type="file" id="fileUpload"
                                                (change)="onFileUploadChanged($event,item.REG_QUESTION_ID,'1',item.REG_QUESTION_TYPE)">
                                            <ng-container *ngFor="let ques of item?.dlc_registration_question_answer">
                                                <label for="fileUpload">{{ ques.REG_QUESTION_ANSWER_NAME}}</label>

                                            </ng-container>
                                            <span *ngIf="imgFIle != ''">{{imgFIle}}</span>
                                        </ng-container> -->
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <button class="btn btn-sm btn-primary float-end px-3 ms-3"
                                                (click)="submitAnswers()">Submit &
                                                Next</button>
                                            <!-- <button class="btn btn-sm btn-outline-primary float-end px-3" ;changeSlide('secondSlide')
                                            [disabled]="backBtn" (click)="previous('back',1)">Back</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- *ngIf="secondSlide" -->
                            <div class="card card1 mb-3 d-none" *ngIf="secondSlide">
                                <div class="card-body px-4">
                                    <div class="row">
                                        <strong style="color: red;">Choose any 5 Questions from the below and record
                                            your
                                            audio answering them. Once recorded please upload your recording.</strong>
                                        <div class="pt-3">
                                            1. Introduce yourself. (Brief Introduction may be asked from an intern).br
                                            2. What are your Strengths and Weakness? Mention any two <br>
                                            3. From where you got to know about Social Internship Programme (SIP)? <br>
                                            4. Why you want to join SIP? <br>
                                            5. What are you expectations from SIP? <br>
                                            6. Do you have any Eco Club / Society working on Environment related
                                            activities
                                            in your University? <br>
                                            7. Are you a part of such Club / Society? <br>
                                            8. Are you aware of any innovative practices adopted by these Clubs /
                                            Society
                                            for making campus Green? <br>
                                            9. Can you suggest any Unique, Sustainable and Eco Friendly practice which
                                            can
                                            be adopted by the University for making Environment Sustainable? <br>
                                            10. Have you ever participated in Environment related Seminar / Workshop /
                                            Conference? <br>
                                            11. What were the learning’s from these Seminar / Workshop / Conference?
                                            <br>
                                            12. Have you adopted any Eco - Friendly practice in your personal life? If
                                            yes ,
                                            kindly share in brief <br>
                                            13. In your neighborhood /region/ community can you mention any one major
                                            problem or challenge related to Environment? <br>
                                            14. Suggest any two measures you think that will help to combat these
                                            challenges. <br>
                                            15. What do you think are major Environment related problems in our Country?
                                            <br>
                                            16. If given a chance, would you like to take up field work/ visit on
                                            Environment issues in your Community ?

                                        </div>
                                        <div class="col-xl-6 col-md-12 col-12 mb-3 pt-2">
                                            <label for="textfiled1" class="form-label mb-1"> <b> Upload Video
                                                    File</b>
                                                <span class="text-danger">*</span></label>
                                            <input type="file" class="form-control form-control-sm" id="textfiled1"
                                                aria-describedby="emailHelp"
                                                (change)="onFileUploadChanged($event,0,'2',0)">
                                            <span *ngIf="audioPath != ''">{{audioPath}}</span>
                                        </div>
                                        <div>
                                            <p class="text-danger"><b>Note:</b>The Video recorded should be less than 10
                                                minutes and video size should not be greater then 20 mb.</p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <button class="btn btn-sm btn-primary float-end px-3 ms-3"
                                                (click)="audioSubmit()">Submit</button>
                                            <!-- <button class="btn btn-sm btn-outline-primary float-end px-3"
                                            (click)="previous('back1',2)" [disabled]="backBtn">Back</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- <app-student-address *ngIf="isLoginDetailsSubmitted" (addressEvent)="getAddressDetails($event)"></app-student-address> -->