<section>
    <div class="section page-banner-section" style="background-image: url('../../../../assets/img/inner-banner.png');">
        <div class="shape-2"></div>
        <div class="container">
            <div class="page-banner-wrap">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Page Banner Content Start -->
                        <div class="page-banner text-center">
                            <h2 class="title">Contact Us</h2>
                            <ul class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ul>
                        </div>
                        <!-- Page Banner Content End -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>


<section class="pt-5 pb-3 mb-3">
    <div class="container">
        <div class="row">
            <div class="col-xl-7">
                <div class="card h-100 shadow">
                    <div class="card-body p-4">
                        <form  [formGroup] ="contactUsForm" (onSubmit)="onSubmitForm(contactUsForm)" class="row" >

                            <!-- Name input -->
                            <div class="mb-3 col-xl-4">
                                <label class="form-label" for="name">Name<span class="text-danger">*</span></label>
                                <input class="form-control" id="name" type="text" placeholder="Name"  formControlName="CONTACT_NAME"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                                    data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="name:required">Name is required.</div>
                            </div>
                            <div class="mb-3 col-xl-4">
                                <label class="form-label" for="mobile">Mobile</label>
                                <input class="form-control" id="mobile" type="text" placeholder="Mobile"  formControlName="MobileNo"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="10" minlength="10"
                                    data-sb-validations="required" />
                                <div class="invalid-feedback" data-sb-feedback="name:required">Name is required.</div>
                            </div>

                            <!-- Email address input -->
                            <div class="mb-3 col-xl-4">
                                <label class="form-label" for="emailAddress">Email Address<span class="text-danger">*</span></label>
                                <input class="form-control" id="emailAddress" type="email" placeholder="Email Address"  formControlName="CONTACT_EMAILID"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                (change)="validateEmail($event.target.value)" >

                                <div class="invalid-feedback" data-sb-feedback="emailAddress:required">Email Address is
                                    required.</div>
                                <div class="invalid-feedback" data-sb-feedback="emailAddress:email">Email Address Email
                                    is not valid.</div>
                            </div>

                            <!-- Message input -->
                            <div class="mb-3">
                                <label class="form-label" for="message">Message<span class="text-danger">*</span></label>
                                <textarea class="form-control" id="message" type="text" placeholder="Message"  formControlName="CONTACT_DESCRIPTION"
                                    style="height: 10rem;" data-sb-validations="required"></textarea>
                                <div class="invalid-feedback" data-sb-feedback="message:required">Message is required.
                                </div>
                            </div>

                            <!-- Form submissions success message -->
                            <div class="d-none" id="submitSuccessMessage">
                                <div class="text-center mb-3">Form submission successful!</div>
                            </div>

                            <!-- Form submissions error message -->
                            <div class="d-none" id="submitErrorMessage">
                                <div class="text-center text-danger mb-3">Error sending message!</div>
                            </div>

                           

                        </form>
                         <!-- Form submit button -->
                         <div class="text-center">
                            <button class="btn btn-primary text-white px-4" id="submitButton"
                                type="button" (click)="onSubmitForm(contactUsForm)">Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-5">
                <div class="row">
                    <div class="col-12 mb-3">

                        <div class="card h-100 text-center shadow">
                            <div class="card-body">
                                <div class="info-icon mb-3">
                                    <i class="fas fa-phone-alt fa-2x"></i>
                                </div>
                                <h5 class="title">Reach us at:</h5>
                                Samvaad InfoTech Pvt Ltd.
                                <br>#1-8-448, Lakshmi Building,
                                <br>5th & 6th Floors, Begumpet, Hyderabad, Telangana.
                                India,
                                <br>PIN 500 003.
                                <br>Ph: (+91) 4049135000

                            </div>
                        </div>


                    </div>
                    <div class="col-12">

                        <div class="card h-100 text-center shadow">
                            <div class="card-body">
                                <div class="info-icon">
                                    <i class="fas fa-comment-alt fa-2x"></i>
                                </div>
                                <h5 class="title">Support</h5>

                                If you need assistance as a learner, visit our learner help centre to find FAQs, or get
                                in touch with our learner support team.
                                <div>
                                    <span> <i class="fas fa-envelope "></i></span>&nbsp;&nbsp;<a
                                        href="mailto:support@samvaadpro.com"
                                        class="text-dark">support@samvaadpro.com</a>

                                </div>

                            </div>
                        </div>


                    </div>
                    <!-- <div class="col-12">
        
                        <div class="card h-100 text-center shadow">
                            <div class="card-body">
                                <div class="info-icon mb-3">
                                    <i class="fas fa-envelope fa-2x"></i>
                                </div>
                                <h5 class="title">Email Id</h5>
        
                                <a href = "mailto:support@samvaadpro.com" class="text-dark">support@samvaadpro.com</a>
                                
                            </div>
                        </div>
        
        
                    </div> -->
                </div>
            </div>



        </div>
        
        <div class="row mt-3">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d475.7923299924148!2d78.4734828016877!3d17.443498579274003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90a73f2312b5%3A0xc385f6645cb07575!2sSamvaad%20Pro!5e0!3m2!1sen!2sin!4v1662631598508!5m2!1sen!2sin" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>

            </div>
        </div>

    </div>
</section>