import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  myForm: FormGroup;


  constructor(private commonService: CommonService, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      instuitionName: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobileNo: ['', [Validators.required,Validators.maxLength(10)]],
      noOfStudents: ['', Validators.required]
    })
  }

  onSubmit(form: FormGroup) {
    let value: any = form.value;
    let payload = {
      "instuition_name": value.instuition_name,
      "in_charge_name": value.in_charge_name,
      "email_id": value.email_id,
      "phone_number": value.phone_number,
      "total_students": value.total_students,
      "createdby": localStorage.getItem('UserId'),
      "modifiedby": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.commonService.postCall('EmsEnquiry/Create', payload).subscribe((res: any) => {
      this.toastr.success("Registration Submitted successfully !!");
    }, e => {
      this.toastr.warning("Registration Not Submitted !!");
     });
    this.myForm.reset();
  }

}
