import { ChatService } from 'src/app/services/chat.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './services/common.service';
declare const register: any;
declare const statusRegister: any
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Lmsapp';

  constructor(private route: Router, private spinner: NgxSpinnerService, private commonService: CommonService, private chatService: ChatService) {
    commonService.appComponent = this
  }
  ngOnInit(): void {
    console.log("AppComponent");
    const { isLogIn } = sessionStorage;
    if (Boolean(isLogIn) == true)
      this.Refresh_WebSockets();

    if ((<any>window).is_login == false)
      this.openVeduURLs();
  }
  activateSpinner() {
    this.spinner.show();
  }
  deactivateSpinner() {
    this.spinner.hide();
  }

  Refresh_WebSockets() {
    //After refresh the web browser get user inforamtion from Session Storage.
    if (sessionStorage.ChatUser) {
      const { TenantCode, UserId, userType, Username } = JSON.parse(sessionStorage.ChatUser);
      this.chatService.connectSocket(TenantCode, UserId, userType, Username);
    }
    const { usrNme = "" } = sessionStorage;
    (<HTMLInputElement>document.getElementById('user')).value = usrNme;

  }

  openVeduURLs() {
    setTimeout(() => {
      const { RoleId = 0, OpenVidu_URL = "", WebSocket_URL = "", usrNme = "" } = sessionStorage;
      if (+RoleId == 2) {
        if (!sessionStorage.userInfo)
          return;

        const courseList = JSON.parse(sessionStorage.courseList);
        const { toId, fromId, name, userType, usrTkn } = JSON.parse(sessionStorage.userInfo);

        (<any>window).courseInfo = courseList;
        (<any>window).OPENVIDU_SERVER_URL = OpenVidu_URL;
        (<any>window).OPENVIDU_SERVER_SECRET = "dipl123";
        (<any>window).vidmed_url = `${WebSocket_URL}telecon/`;

        (<HTMLInputElement>document.getElementById('ovUrl')).value = OpenVidu_URL;
        (<HTMLInputElement>document.getElementById('ovKey')).value = "dipl123";
        (<HTMLInputElement>document.getElementById('apUrl')).value = WebSocket_URL;
        (<HTMLInputElement>document.getElementById('sessionName')).value = fromId;
        (<HTMLInputElement>document.getElementById('fromId')).value = fromId;
        (<HTMLInputElement>document.getElementById('toId')).value = toId;
        (<HTMLInputElement>document.getElementById('newUserId')).value = usrTkn;

        if (+userType === 1) {
          register(toId, fromId, name, userType);
          statusRegister();
        }
      }
    }, 100);
  }

}
