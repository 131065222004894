import { ConnectionService } from 'ng-connection-service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

declare const answerCall: any;
declare let JqueryReady: any;
declare let joinSession: any;
@Component({
  selector: 'app-open-vidu-data',
  templateUrl: './open-vidu-data.component.html',
  styleUrls: ['./open-vidu-data.component.css']
})
export class OpenViduDataComponent implements OnInit {
  status = 'ONLINE';
  isConnected = true;
  online$: Observable<boolean>;
  isConnectionAvailable: boolean;
  sourcetitle = "Student";
  subscription_refresh: Subscription;
  isShowVideo: boolean = true;
  constructor(private router: Router
    , private toastr: ToastrService
    , private connectionService: ConnectionService
  ) { }

  ngOnInit(): void {
    this.check_internet();
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.networkStatus();
  }

  acceptCall() {
    sessionStorage.setItem('call_type', 'Video');
    sessionStorage.setItem('call_type_id', '' + 1);
    let fromId = (<HTMLInputElement>document.getElementById('fromId')).value, toId = (<HTMLInputElement>document.getElementById('toId')).value;
    this.isShowVideo = true;
    setTimeout(() => {
      // Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); joinSession(); });
      Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId); joinSession(); });
    }, 1000);
  }

  check_internet() {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "Network is available  now";
        this.toastr.success(this.status);
      }
      else {
        this.status = "Network not available ";
        this.toastr.error(this.status);
      }
    })
  }

  declineCall() {
    const { RoleId = 0 } = sessionStorage;
    this.toastr.error(`${(RoleId == 2) ? 'Student' : 'Teacher'} has disconnected the call`);
  }
  declineDocCall() {
    this.toastr.error('Teacher has disconnected the call');
  }
  viduException() {
    this.toastr.error('Exception occurred');
  }

  networkStatus() {
    this.online$.subscribe(value => this.isConnectionAvailable = value);
  }

  onRec(isStrt: boolean) {
    // if (isStrt) {
    //   let ovUrl = (<HTMLInputElement>document.getElementById('ovUrl')).value, ovKey = (<HTMLInputElement>document.getElementById('ovKey')).value,
    //     ssnId = (<HTMLInputElement>document.getElementById('forceRecordingId')).value, srPL = JSON.stringify({
    //       "session": ssnId,
    //       "name": ssnId, "outputMode": 'COMPOSED', "hasAudio": true, "hasVideo": true, "resolution": "1280x720", "recordingLayout": "BEST_FIT"
    //     });
    //   const hO = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + ovKey) };
    //   this.apiSvc.post3(`${ovUrl}api/recordings/start`, srPL, hO).subscribe(res => { console.log(res), console.log('eRecord started'); });
    // }
    // else
    //   alert('eRecord stopped');
  }

  endAgntCall() {
    // this.router.navigate(['HOME/agentlistcall']);
  }

  onPatient_connection() {
    this.toastr.error('Student/Health Care Worker side connection lost');
  }
  disconnect_connection() {
    this.toastr.error('Connection Problem, Oops! Trying to reconnect to the session');
  }
  pocopenrote() {
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/HOME/poc']);
    // });
  }
  sourcetype() {
    this.sourcetitle = "Professor";
  }
  sourcetypeclick() {
    this.sourcetitle = "Student";
  }
  // sourcetypenew(){
  //   this.sourcetitle="Doctor";
  // }
  // sourcetypeclicknew(){
  //   this.sourcetitle="Doctor";
  // }
  ngOnDestroy() {
    this.subscription_refresh.unsubscribe();
  }

}
