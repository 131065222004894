import { SpeechToTextComponent } from './speech-to-text/speech-to-text.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { CoursesResolver } from './resolvers/coursesResolver.resolver';
import { ERPComponent } from './erp/erp.component';
import { PrivacyPolicyComponent } from './components/organization-details/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/organization-details/terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './components/organization-details/refund-policy/refund-policy.component';
import { ContactUs1Component } from './components/organization-details/contact-us/contact-us1.component';
import { ViewCourseDetailsComponent } from './components/view-course-details/view-course-details.component';
import { EnquiryComponent } from './pages/enquiry/enquiry.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { TutorHomeComponent } from './pages/tutor-home/tutor-home.component';
import { StudentHomeComponent } from './pages/student-home/student-home.component';
import { AllCategoryCoursesComponent } from './pages/all-category-courses/all-category-courses.component';
import { StudentSignupComponent } from './pages/student-signup/student-signup.component';
import { HeaderNewComponent } from './components/Shared/header-new/header-new.component';
import { FooterNewComponent } from './components/Shared/footer/footer-new.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
// import { AllCategoriesComponent } from './pages/other-components/all-categories/all-categories.component';
import { AllCoursesComponent } from './pages/all-courses/all-courses.component';
import { TestMonialsComponent } from './components/test-monials/test-monials.component';
import { InvoiceComponent } from './pages/other-components/invoice/invoice.component';
import { TextToSpeechComponent } from './text-to-speech/text-to-speech.component';
import { SippaRegistrationComponent } from './pages/sippa-registration/sippa-registration.component';




const routes: Routes = [
  {
    path: 'enquiry',
    component: EnquiryComponent
  },
  // {
  //   path: "privacyPolicy",
  //   component: PrivacyPolicyComponent
  // },
  {
    path: 'HOME',
    component: HomeComponent,

    children: [
      {
        path: 'dashboard',
        component: MaincontentComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      { path: 'SpeechToText', component: SpeechToTextComponent },
      { path: 'TextToSpeech', component: TextToSpeechComponent },
      { path: 'canteenModule', loadChildren: () => import('./pages/Administration/canteen-Management/canteen-management.module').then(m => m.CanteenManagementModule) },
      { path: 'configure', loadChildren: () => import('./pages/Administration/Configure/configure.module').then(m => m.ConfigureModule) },
      { path: 'front-office', loadChildren: () => import('./pages/Administration/Front-Office/front-office.module').then(m => m.FrontOfficeModule) },
      { path: 'hostel', loadChildren: () => import('./pages/Administration/Hostel/hostel.module').then(m => m.HostelModule) },
      { path: 'inventory', loadChildren: () => import('./pages/Administration/Inventory/inventory.module').then(m => m.InventoryModule) },
      { path: 'leaveManagement', loadChildren: () => import('./pages/Administration/Leave-Management/leave-management.module').then(m => m.LeaveManagementModule) },
      { path: 'libraryManagement', loadChildren: () => import('./pages/Administration/Library-management/library-management.module').then(m => m.LibraryManagementModule) },
      { path: 'timetable', loadChildren: () => import('./pages/Administration/timetable/timetable.module').then(m => m.TimetableModule) },
      { path: 'transport', loadChildren: () => import('./pages/Administration/Transport/transport.module').then(m => m.TransportModule) },
      { path: 'communication', loadChildren: () => import('./pages/Communication/communication.module').then(m => m.CommunicationModule) },
      { path: 'courseSetup', loadChildren: () => import('./pages/Course-setup/course-setup.module').then(m => m.CourseSetupModule) },
      { path: 'events', loadChildren: () => import('./pages/Events/events.module').then(m => m.EventsModule) },
      { path: 'Assessment', loadChildren: () => import('./pages/Examination/Assessment/assessment.module').then(m => m.AssessmentModule) },
      { path: 'Assignment', loadChildren: () => import('./pages/Examination/Assignment/assignment.module').then(m => m.AssignmentModule) },
      { path: 'feeManagement', loadChildren: () => import('./pages/fee-management/fee-management.module').then(m => m.FeeManagementModule) },
      { path: 'globalInputs', loadChildren: () => import('./pages/global-inputs/global-inputs.module').then(m => m.GlobalInputsModule) },
      { path: 'misReports', loadChildren: () => import('./pages/mis-reports/mis-reports.module').then(m => m.MisReportsModule) },
      { path: 'emsReports', loadChildren: () => import('./pages/mis-reports/ems-reports/ems-reports.module').then(m => m.EmsReportsModule) },
      { path: 'taskManagement', loadChildren: () => import('./pages/task-management/task-management.module').then(m => m.TaskManagementModule) },
      { path: 'registration', loadChildren: () => import('./pages/Registration/registration.module').then(m => m.RegistrationModule) },
      { path: 'generalStore', loadChildren: () => import('./pages/general-store/general-store.module').then(m => m.GeneralStoreModule) },
      { path: 'components', loadChildren: () => import('./pages/other-components/other-components.module').then(m => m.OtherComponentsModule) },
      { path: 'cautionDeposit', loadChildren: () => import('./pages/Administration/caution-deposit/caution-deposit.module').then(m => m.CautionDepositModule) },


      {
        path: 'student-home', component: StudentHomeComponent, resolve: { courseData: CoursesResolver }
      },
      {
        path: 'invoice', component: InvoiceComponent
      },
    ]

  },
  {
    path: '',
    redirectTo: 'tutorHome',
    pathMatch: 'full'
  },
  {
    path: 'tutorHome',
    component: TutorHomeComponent, resolve: { courseData: CoursesResolver }
  },
  {
    path: 'student-home', component: StudentHomeComponent, resolve: { courseData: CoursesResolver }
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'Home/dashboard',
    redirectTo: 'HOME',
  },
  {
    path: 'all-category-courses',
    component: AllCategoryCoursesComponent
  },
  { path: 'student-signup', component: StudentSignupComponent },
  { path: 'view-course-details', component: ViewCourseDetailsComponent },
  {path:'sreg',component:SippaRegistrationComponent},

  {
    path: 'eRP', component: ERPComponent,
    children: [
      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact-us', component: ContactUs1Component },
      { path: 'refund-policy', component: RefundPolicyComponent },
      { path: 'terms-conditions', component: TermsConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'view-course-details', component: ViewCourseDetailsComponent },
      // { path: 'view-profile', component: ViewProfileComponent },
    ]
  },


  {
    path: '**',
    redirectTo: 'tutorHome'
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// resolve:{menu:Resolver},
