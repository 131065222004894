<body>
    <div class="wrapper">
        <header>Text To Speech</header>
        <form action="#">
            <div class="row">
                <label>Enter Text</label>
                <textarea id="txtArea" [(ngModel)]="message" [ngModelOptions]="{standalone: true}"></textarea>
            </div>
            <div class="row">
                <label>Select Voice</label>
                <div class="outer">
                    <select id="drpVoice"></select>
                </div>
            </div>
            <button id="btnTextSpeech" [attr.disabled]="(message.trim().length==0)?true:null">Convert To Speech</button>
            <button style="background-color: red;" (click)="back()">back</button>
        </form>
    </div>
</body>