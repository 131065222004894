import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
declare const call: any;
declare const register: any;
@Component({
  selector: 'app-online-teachers',
  templateUrl: './online-teachers.component.html',
  styleUrls: ['./online-teachers.component.css']
})
export class OnlineTeachersComponent implements OnInit {
  onlineTeachers: Array<any> = [];

  WebSocket_URL = environment.WebSocket_URL;
  OpenVidu_URL = environment.OpenVidu_URL;;

  TeachersCount: number = 0;
  IsConfirmation: boolean = false;
  TeacherName: string = "";

  courseList: Array<any> = [];
  CourseIdsList: Array<any> = [];

  constructor(private CommonService: CommonService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.isStudent();
  }

  isStudent() {
    const { TenantCode = 0, UserId = 0, RoleId = 0 } = sessionStorage;
    if (+RoleId == 3) {
      this.openVeduURLs();
      this.CommonService.postCall("Courses/LoadCoursesByStudent", { USER_ID: UserId, TNT_CODE: TenantCode }).subscribe(rep => {

        if (rep) {
          this.courseList = rep.map(e => ({ courseId: e.COURSE_ID, courseName: e.COURSE_NAME }));
          this.CourseIdsList = [];
          this.courseList = this.courseList.filter(e => {
            const isNotExist: boolean = !this.CourseIdsList.includes(e.courseId);
            if (isNotExist)
              this.CourseIdsList.push(e.courseId);
            return isNotExist;
          });
          (<any>window).stopTeacher_Interval = setInterval(() => this.getOnlineTeachers(TenantCode), 10000);
        }
      });
    }
  }

  getOnlineTeachers(TenantCode: number) {
    // [this.TeachersCount, this.onlineTeachers] = [0, []];
    this.CommonService.getData(`telecon/doctorstatus/getall/?TENANT_ID=${TenantCode}`).subscribe(res => {
      let new_onlineTeachers: Array<any> = res.filter(e => e.courseInfo?.map(ee => ee.courseId).some(m => this.CourseIdsList.includes(m)));

      [this.onlineTeachers, this.TeachersCount] = [new_onlineTeachers, new_onlineTeachers.length];

      // new_onlineTeachers = new_onlineTeachers.filter(e => !this.onlineTeachers.map(m => m.doctorId.split('_')[1]).includes(e.doctorId.split('_')[1]));
      // if (new_onlineTeachers.length > 0) {
      //   this.onlineTeachers = [...this.onlineTeachers, ...new_onlineTeachers];
      //   this.TeachersCount = this.onlineTeachers.length;
      // }
    });
  }

  confirmationMessage(data: any) {
    if (data) {
      if (data.status == "BUSY") {
        this.toastr.error("Teacher is busy with another call");
        return;
      }
      else if (data.status == "AVAILABLE") {
        if (confirm(`Are you sure, want to call Prof. ${data.name.toUpperCase()}?`)) {
          Promise.all([this.webRTC_method(data.doctorId)]).then(e => setTimeout(() => call(data.doctorId, sessionStorage.UserId, data.name.toUpperCase(), '', ''), 1000));
        }
      }
    }
  }

  MessageStatus(status: boolean) {
    if (status)
      this.toastr.success("Accepted");
    else
      this.toastr.error("Rejected");
  }

  openVeduURLs() {

    (<HTMLInputElement>document.getElementById('ovUrl')).value = this.OpenVidu_URL;
    (<HTMLInputElement>document.getElementById('ovKey')).value = "dipl123";
    (<HTMLInputElement>document.getElementById('apUrl')).value = this.WebSocket_URL;

    (<any>window).OPENVIDU_SERVER_URL = this.OpenVidu_URL;
    (<any>window).OPENVIDU_SERVER_SECRET = "dipl123";
    (<any>window).vidmed_url = `${this.WebSocket_URL}telecon/`;
  }

  webRTC_method(teacher_id: '') {
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    const userInfo = {
      toId: teacher_id,
      fromId: `stu_${userData.USERID}`,
      name: userData.FIRSTNAME,
      userType: 'Student',
    };

    // this.isRegistered = true;
    let usrTkn = `org_${userData.TENANTCODE}_${userData.TNT_NAME}_${userInfo.fromId}_${userData.FIRSTNAME}_${(new Date()).getTime()}`.replace(/ /g, '_');
    (<HTMLInputElement>document.getElementById('sessionName')).value = userInfo.fromId;
    (<HTMLInputElement>document.getElementById('fromId')).value = userInfo.fromId;
    (<HTMLInputElement>document.getElementById('toId')).value = userInfo.toId;

    (<HTMLInputElement>document.getElementById('newUserId')).value = usrTkn;

    register(userInfo.toId, userInfo.fromId, userInfo.name, userInfo.userType);

  }

}
