import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const $: any;
@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.css']
})
export class HeaderNewComponent implements OnInit {
  @Input() categoryList: Array<{ id: number, name: string }> = [];
  @Output() categoryEvent = new EventEmitter<number>();

  constructor(private route: Router) { }

  ngOnInit(): void {
    this.SearchModel();
    setTimeout(() => this.companyDetails(), 10);
  }

  SearchModel() {
    $(document).ready(function () {
      $('#close-btn').click(function () {
        $('#search-overlay').fadeOut();
        $('#search-btn').show();
      });
      $('#search-btn').click(function () {
        $(this).hide();
        $('#search-overlay').fadeIn();
      });
    });
  }

  onCategoryChanged(categoryId: number) {
    this.categoryEvent.emit(categoryId);
    this.gotoSection();
  }

  gotoSection() {
    $(document).scrollTop(1100);
  }

  companyDetails() {
    const { fileUrl } = environment;
    if (sessionStorage.landingpageimage_path) {
      document.getElementById("landingpageimage_path").setAttribute("src", `${fileUrl}${sessionStorage.landingpageimage_path} `);

    }
  }

  NavigateTo() {
    // const URL = (/true/).test(sessionStorage.isDomain) ? "/student-signup" : "/signup";
    // this.route.navigate([URL]);
    this.route.navigate(['/student-signup']);
  }

}
