<li class="nav-item dropdown dropdown-large" *ngIf="roleId=='2'">
  <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
    data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">{{studentCount}}</span>
    <!-- <i class="fas fa-comment-dots" aria-hidden="true"></i> -->
    <i class='bx bxs-envelope' aria-hidden="true"></i>
  </a>
  <div class="dropdown-menu dropdown-menu-end online-drop">
    <a href="javascript:;">
      <div class="msg-header">
        <p class="msg-header-title">Students List</p>
      </div>
    </a>
    <div style="overflow-y:auto; height:360px;">
      <div class="header-notifications-list" *ngFor="let item of studentList">
        <a class="dropdown-item" href="javascript:;" (click)="loadChat(item,true)">

          <div class="d-flex align-items-center">
            <div class="notify"><i class="bx bx-group"></i>
            </div>
            <div>
              <div class="flex-grow-1">Stud .{{item.student_name}}
              </div>
            </div>

          </div>
        </a>

      </div>
    </div>

  </div>
</li>
<li class="nav-item dropdown dropdown-large" *ngIf="roleId=='3'">
  <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button"
    data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">{{teachersCount}}</span>
    <i class="bx bxs-envelope" aria-hidden="true"></i> </a>
  <div class="dropdown-menu dropdown-menu-end online-drop">
    <a href="javascript:;">
      <div class="msg-header">
        <p class="msg-header-title">Online Teachers</p>
      </div>
    </a>
    <div style="overflow-y:auto; height:360px;">
      <div class="header-notifications-list" *ngFor="let item of onlineTeachers">
        <a class="dropdown-item" href="javascript:;" (click)="loadChat(item,true)">

          <div class="d-flex align-items-center">
            <div class="notify"><i class="bx bx-group"></i>
            </div>
            <div>
              <div class="flex-grow-1">Prof .{{item.name}}
              </div>
            </div>

          </div>
        </a>

      </div>
    </div>

  </div>
</li>

<input type="button" class="d-none" id="btnShowChatModel" data-bs-toggle="modal" data-bs-target="#myMessagesNew">
<div class="modal" *ngIf="isChatModelOpened" id="myMessagesNew" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header">
        <!-- <h5 class="mb-0 w-100 text-white"> Chat History</h5> -->
        <div class="d-flex justify-content-between align-items-center border-0">
          <div class="d-flex align-items-center">
            <div class="pe-3"><img src="https://i.pinimg.com/736x/89/90/48/899048ab0cc455154006fdb9676964b3.jpg"
                width="50px" class="img-fluid rounded-circle" alt="" style="border: 1px #828282 solid;"></div>
            <div>
              <h6 class="mb-0">{{name}}</h6>
              <p class="mb-0" style="color: #fff; text-align: left">Online</p>
            </div>
          </div>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="close()" aria-label="Close"></button>

      </div>


      <div class="modal-body p-0">
        
            <div class="card card-1 card-shadow border-0 h-100">
              <!-- <div class="card-header d-flex justify-content-between align-items-center border-0 pt-3 pb-3"
                style="box-shadow: 0px 4px 20px 0px #0000001A;">
                <div class="d-flex align-items-center">
                  <div class="pe-3"><img src="https://i.pinimg.com/736x/89/90/48/899048ab0cc455154006fdb9676964b3.jpg" width="50px" class="img-fluid rounded-circle" alt=""
                      style="border: 1px #828282 solid;"></div>
                  <div>
                    <h6 class="mb-0">{{name}}</h6>
                    <p class="mb-0" style="color: #545454;">Online</p>
                  </div>
                </div>
              </div> -->
              <div class="card-title text-center p-2cq pb-3 d-none" style="color: #828282;">
                <p class="mb-0">Today<br>11:36</p>
              </div>
              <div class="">
                <div class="chat-box" style="height: 60vh; overflow: auto;  scroll-behavior: smooth;">
                  <section id="message-list" *ngFor="let item of chatMessagesList;let ind=index;">

                    <!-- <hr> -->

                    <!-- <ng-container id="chatalign" *ngFor="let item of chatMessagesList;let ind=index;"> -->
                    <!-- <span style="margin-left: 20px;font-size: medium;" *ngIf="item.beneficiary_id == benificiary_id || item.usertype == 'Doctor'">{{item.message}}</span> -->
                    <div class="chat1 ">
                      <span *ngIf="item.student_id == userInfo.student_id && item.usertype != 'Teacher' "
                        id="chatShow"><b>{{item.student_name}}:</b>&nbsp;&nbsp;{{item.message}}<br>
                        <div class="m-0 mt-1" style="color: #918a8a; font-size: 0.65rem;">
                          &nbsp;&nbsp;&nbsp;{{item.curentDate|date:'medium'}} <i class="far fa-clock"></i></div>
                      </span>
                    </div>
                    <div class="chat2">
                      <span
                        *ngIf="item.usertype == 'Teacher' && item.student_id == userInfo.student_id && item.teacher_id == userInfo.teacher_id"
                        id="chatShoww"><b> Prof. {{item.teacher_name}}:</b>&nbsp;&nbsp;{{item.message}}<br>
                        <div class="text-end m-0 mt-1" style="color: #918a8a; font-size: 0.65rem;">
                          &nbsp;&nbsp;&nbsp;{{item.curentDate|date:'medium'}} <i class="far fa-clock"></i></div>
                      </span>
                    </div>


                    <!-- </ng-container> -->
                  </section>
                </div>
              </div>
              <form (ngSubmit)="sendMessage($event)">
                <div class="modal-footer p-2 ">
                  <div class='col-12'>
                    <div class="form-group">
                      <div class="input_wrapper2" style="position: relative;">
                        <input type="text" id="search_string" name="search_string" (keypress)="ChatKeyPress($event)"
                          [(ngModel)]="chatMessage" autocomplete="off" class="border-0 form-control form-control-sm"
                          placeholder="Type a message">

                        <div class="unit" *ngIf="chatMessage !=''" style="position: absolute;
                            top: 0px;
                            right: 2px;
                            padding: 0;">
                          <button type="submit" class="btn btn-sm btn-red"><i class="fa fa-paper-plane"
                              aria-hidden="true"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

      </div>
      <!-- </div> -->



    </div>

  </div>


</div>
<!-- </div> -->