<!-- <ngx-spinner></ngx-spinner> -->
<div class="wrapper">

    <app-open-vidu-data></app-open-vidu-data>
    <!-- <ngx-spinner bdColor = "rgba(0,0,0,0.1)" size = "medium" color = "#464343" type = "ball-spin-clockwise"></ngx-spinner> -->
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
        <!-- <p style="color: white"> Please Wait... </p> -->
    </ngx-spinner>
    <router-outlet></router-outlet>
    <div class="overlay toggle-icon"></div>
</div>