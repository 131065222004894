import { VoiceRecognitionService } from './../services/voice-recognition.service';
import { Component, OnInit } from '@angular/core';
// import { VoiceRecognitionService } from 'src/service/voice-recognition.service';

@Component({
  selector: 'app-speech-to-text',
  templateUrl: './speech-to-text.component.html',
  styleUrls: ['./speech-to-text.component.css']
})
export class SpeechToTextComponent implements OnInit {
  isStartSpeech: boolean = false;
  disableButtons:boolean=false;
  constructor(public service: VoiceRecognitionService) {
    this.service.init();
  }

  ngOnInit(): void { }

  startService() {
    this.isStartSpeech = true;
    this.service.start();
    let startSpeech :any =document.getElementById('startSpeech')?.click;
    if(startSpeech){
      this.disableButtons =false;
    }
  }

  stopService() {
    this.isStartSpeech = false;
    this.service.stop();
    let stopSpech :any=document.getElementById('stopSpeech')?.click()
    if(stopSpech){this.disableButtons=true;}
  }

  download() {
    const [text, filename] = [this.service.speechMessage, "speech.txt"];
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.disableButtons =true;
    this.ClearText();
  }

  ClearText() {
    this.service.speechMessage = "";
  }

  back() {
    window.history.back();
  }
}
