<div class="container-fluid">
    <div class="row">
        <div class="col-xl-8 col-md-6 col-sm-12 bg-img order-xl-first order-md-first order-last">
            <div class="row d-flex justify-content-center me-xl-5 mt-4 mt-xl-0 mt-md-0 mb-4 mb-xl-0 mb-md-0">
                <div class="col-xl-8 col-md-10  col-sm-12 mt-md-4 pe-xl-5">
                    <div class="card mt-4 p-0 border-0"
                        style="background-color:rgba(0,0,0,0.6); border-radius: 0.5rem;">
                        <div class="card-header text-light fw-bold h5 text-center border-0 pt-3 pb-3"
                            style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem;">
                            Register Now
                        </div>
                        <div class="card-body p-xl-4">

                            <div class="form">
                                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">NAME OF
                                                THE INSTUITION</label>
                                            <input type="text" class="form-control form-control1" id="example"
                                                placeholder="" formControlName='instuitionName'>
                                            <div *ngIf="myForm.get('instuitionName')?.touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('instuitionName')?.hasError('required')">
                                                    Please Enter THE INSTUITION
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-12 mb-3">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">Name of
                                                The Person In-Charge</label>
                                            <input type="text" class="form-control" id="example" placeholder=""
                                                formControlName='name'>
                                            <div *ngIf="myForm.get('name')?.touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('name')?.hasError('required')">
                                                    Please Enter The Person In-Charge
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">EMAIL
                                                ID</label>
                                            <input type="email" class="form-control" id="example" placeholder=""
                                                formControlName='email'
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                            <div *ngIf="myForm.get('email')?.touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('email')?.hasError('required')">
                                                    Please Enter ID
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">Phone
                                                number</label>
                                            <input type="number" class="form-control" id="example"
                                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                type="number" maxlength="10" placeholder="" formControlName='mobileNo'>
                                            <div *ngIf="myForm.get('mobileNo')?.touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('mobileNo')?.hasError('required')">
                                                    Please Enter Phone Number
                                                </span>
                                            </div>

                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="exampleFormControlInput1" class="form-label mb-1">Number of
                                                Students</label>
                                            <input type="number" class="form-control" id="example" placeholder=""
                                                formControlName='noOfStudents'>
                                            <div *ngIf="myForm.get('noOfStudents')?.touched">
                                                <span class="text-danger"
                                                    *ngIf="myForm.get('noOfStudents')?.hasError('required')">
                                                    Please Enter Number of Students
                                                </span>
                                            </div>

                                        </div>

                                        <div class="col-12 mb-2 mt-3 text-center">
                                            <button type="submit" class="btn submit-btn w-100  text-center px-5 py-2"
                                                style="border-radius: 0.5rem" (click)="onSubmit(myForm)"
                                                [disabled]="myForm.invalid">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="col-xl-4 col-md-6 col-sm-12 pt-3 bg-dark text-light p-4 pt-5 order-xl-last order-md-last order-first">
            <div class="text-center mt-3 mb-5">
                <span class="text-center bg-light px-4 pt-2 pb-3" style="border-radius: 0.5rem"><img
                        src="../../../assets/img/enquiry_images/Samvaad-logo.png" class="img-fluid" alt=""></span>
            </div>
            <div class="clearfix"></div>
            <h5 class="mb-3">REGISTER NOW TO GET UPTO 99% OFF ON ERP IMPLEMENTATION*</h5>
            <p>Welcome to Samvaad, your one stop for all the educational needs</p>
            <p>Samvaad offers an entire student, teacher and campus administration lifecycle on one platform</p>
            <p>It is an integrated platform with a state of the art audio video conferencing tool, content
                provisioning system and campus administration modules.</p>
            <p>It is more than just an LMS &amp; ERP.</p>
            <h5>GET ASSURED DISCOUNT ON EVERY REGISTRATION</h5>

            <div style="font-size: 12px;">
                <p class="mt-5 mb-0" style="text-indent: -1.1em; margin-left: 1.1em">*&nbsp;&nbsp;Terms and
                    conditions apply, offer valid for 10 colleges which will be randomly selected upon
                    registering</p>
                <p style="text-indent: -1.1em; margin-left: 1.1em">*&nbsp;&nbsp;implementation charges will not
                    be charged to the selected colleges.</p>
            </div>
        </div>
    </div>
</div>