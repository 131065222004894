<header>
    <div class="row bg-header-logos">
        <div class="col-6">
            <span class=" float-start p-2"><img src="../../../assets/img//eco mitram logo/logo-96px.png"
                    class="img-fluid " height="auto"></span>
        </div>
        <div class="col-6">
            <span class=" float-end p-2"><img src="../../../assets/img//eco mitram logo/logo-96px.png"
                    class="img-fluid  float-end pt-2" height="auto"></span>
        </div>
    </div>
    <!-- <div class="d-block bg-header-logos">
      <span class=" float-start p-2"><img src="../../../assets/images/Gita_logo.png" class="img-fluid "
           height="auto"></span>
      <span class=" float-end p-2"><img src="../../../assets/images/geeta_text_logo-1.png"
          class="img-fluid  float-end pt-3"  height="auto"></span>
    </div> -->
</header>


<section class="gradient-custom">
    <div class="container py-5 ">
        <div class="row justify-content-center align-items-center h-100">
            <div class="col-12 col-lg-9 col-xl-7">
                <div class="card shadow-2-strong card-registration" style="border-radius: 15px;">
                    <div class="card-body p-4 p-md-3">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <h3 class=" float-start pt-3" style="font-weight : 700;">
                                    Registration Form
                                </h3>
                            </div>
                        </div>
                        <!-- [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)" -->
                        <form class="well form-horizontal" >
                            <div class="row ">
                                <div class="col-md-6 ">
                                    <div class="form-outline">
                                        <label class="form-label" for="firstname">Full Name
                                            <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control form-control-md">
                                        <!-- <input type="text" placeholder="Fullname" formControlName="first_name" id="firstName"
                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('first_name').errors }"
                        class="form-control form-control-md" /> -->
                                    </div>

                                    <!-- <div *ngIf="submitted && myForm.get('first_name').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('first_name').errors.required">Name is required.
                      </div>
                    </div> -->


                                </div>

                                <div class="col-md-6  pb-2">
                                    <div class="form-outline">
                                        <label class="form-label" for="phoneNumber">Email
                                        </label> <span class="text-danger">*</span>
                                        <!-- <input type="email" id="email" placeholder="Email" email="true" formControlName="email"
                        [(ngModel)]="Email" class="form-control form-control-md" (blur)="validateEmail($event)"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('email').errors }"
                        class="form-control form-control-md" /> -->
                                        <input type="text" class="form-control form-control-md">

                                    </div>
                                    <!-- <div *ngIf="submitted && myForm.get('email').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('email').errors.required">Email is required.
                      </div>
                    </div> -->
                                </div>

                            </div>
                            <div class="row mt-2">

                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="form-outline datepicker w-100">
                                        <label for="country" class="form-label">Gender
                                            <span class="text-danger">*</span></label>
                                        <!-- [ngClass]="{ 'is-invalid': submitted && myForm.get('gender').errors }" formControlName="gender" -->
                                        <select class="form-select">
                                            <option value="" [selected]="true">Select Gender</option>
                                            <option [value]=1>Male</option>
                                            <option [value]=2>Female</option>
                                            <option [value]=3>Others</option>
                                        </select>
                                    </div>
                                    <!-- <div *ngIf="submitted && myForm.get('gender').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('gender').errors.required">Gender is required.
                      </div>
                    </div> -->
                                </div>


                                <div class="col-md-6  pb-2">
                                    <div class="form-outline datepicker w-100">
                                        <label for="birthdayDate" class="form-label">Date Of Birth
                                        </label> <span class="text-danger">*</span>
                                        <input type="text" class="form-control form-control-md">
                                        <!-- <input type="date" formControlName="dob" (keydown)="disableDate()" [max]="getToday()"
                        class="form-control form-control-md" id="birthdayDate"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('dob').errors }" /> -->
                                    </div>
                                    <!-- <div *ngIf="submitted && myForm.get('dob').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('dob').errors.required">DOB is required.
                      </div>
                    </div> -->
                                </div>


                            </div>

                            <div class="row mt-2">



                                <div class="col-md-6 d-flex align-items-center">
                                    <div class="form-outline datepicker w-100">
                                        <label for="country" class="form-label">Qualification
                                            <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control form-control-md">
                                        <!-- <select class="form-select" formControlName="qualification_id"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('qualification_id').errors }">
                        <option value="" [selected]="true">Select Qualification</option>
                        <option *ngFor="let item of qulificationDropdown" [value]='item.qualification_id'>
                          {{item.qualification_name}}
                        </option>
                      </select> -->
                                    </div>
                                    <!-- <div *ngIf="submitted && myForm.get('qualification_id').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('qualification_id').errors.required">Qualification is required.
                      </div>
                    </div> -->
                                </div>

                                <div class="col-md-6">
                                    <div class="form-outline datepicker w-100">
                                        <label for="country" class="form-label">Country
                                            <span class="text-danger">*</span></label>
                                        <!-- <select class="form-select  " formControlName="country"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('country').errors }"
                        (change)="changeState($event)">
                        <option value="" [selected]="true">Select Country</option>
                        <option *ngFor="let item of countryDropdown" [value]='item.item_id'>
                          {{item.item_text}}
                        </option>
  
                      </select>
                    </div> -->
                                        <!-- <div *ngIf="submitted && myForm.get('country').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('country').errors.required">country is required.
                      </div>
                    </div> -->
                                    </div>



                                </div>
                            </div>
                                <div class="row mt-2">

                                    <div class="col-md-6">
                                        <div class="form-outline datepicker w-100">
                                            <label for="country" class="form-label">State
                                                <span class="text-danger">*</span></label>
                                            <!-- <select class="form-select" formControlName="state"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('state').errors }"
                        (change)="changeCity($event)">
                        <option value="" [selected]="true">Select State</option>
                        <option *ngFor="let item of stateDropdown" [value]='item.item_id'>
                          {{item.item_text}}
                        </option>
  
                      </select> -->
                                            <input type="text" class="form-control form-control-md">
                                        </div>
                                        <!-- <div *ngIf="submitted && myForm.get('state').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('state').errors.required">state is required.
                      </div>
                    </div> -->
                                    </div>
                                    <div class="col-md-6 d-flex align-items-center">

                                        <div class="form-outline datepicker w-100">
                                            <label for="country" class="form-label">city
                                                <span class="text-danger">*</span></label>
                                            <!-- <select class="form-select" formControlName="city" [(ngModel)]="city"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('city').errors }">
                        <option value="" [selected]="true">Select City</option>
                        <option *ngFor="let item of cityDropdown" [value]='item.item_id'>
                          {{item.item_text}}
                        </option>
  
                      </select> -->
                                        </div>
                                        <!-- <div *ngIf="submitted && myForm.get('city').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('city').errors.required">city is required.
                      </div>
                    </div> -->
                                    </div>

                                </div>

                                <div class="row mt-2">



                                    <div class="col-md-6  pb-2">
                                        <div class="form-outline">
                                            <label class="form-label" for="lastname">Mobile Number
                                                <span class="text-danger">*</span></label>
                                            <input type="text" class="form-control form-control-md">
                                            <!-- <input type="tel" id="number" placeholder="Mobile Number" [(ngModel)]="mobile" maxlength="10"
                        formControlName="mobile_number" class="form-control form-control-md"
                        (blur)="validateMobile($event)"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('mobile_number').errors }" /> -->
                                        </div>
                                        <!-- <div *ngIf="submitted && myForm.get('mobile_number').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('mobile_number').errors.required">Mobile Number is required.
                      </div>
                    </div> -->
                                    </div>

                                    <div class="col-md-6 d-flex align-items-center">
                                        <div class="form-outline datepicker w-100">
                                            <label for="aadhar" class="form-label">Pincode
                                            </label><span class="text-danger">*</span>
                                            <!-- <input type="tel" formControlName="pincode"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('pincode').errors }" placeholder="Pincode"
                        class="form-control form-control-md" /> -->
                                            <input type="text" class="form-control form-control-md">
                                        </div>
                                        <!-- <div *ngIf="submitted && myForm.get('pincode').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('pincode').errors.required">pincode is required.
                      </div>
                    </div> -->
                                    </div>

                                </div>
                                <div class="row mt-2">

                                    <div class="col-md-6 d-flex align-items-center">
                                        <div class="form-outline datepicker w-100">
                                            <label for="aadhar" class="form-label">Password
                                            </label><span class="text-danger">*</span>
                                            <!-- <input type="password" [ngClass]="{ 'is-invalid': submitted && myForm.get('pincode').errors }"
                        formControlName="password" placeholder="Password" id="password"
                        class="form-control form-control-md" /> -->
                                            <input type="text" class="form-control form-control-md">
                                        </div>
                                        <!-- <div *ngIf="submitted && myForm.get('password').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('password').errors.required">password is required.
                      </div>
                    </div> -->
                                    </div>
                                    <div class="col-md-6 d-flex align-items-center">
                                        <div class="form-outline datepicker w-100">
                                            <label for="aadhar" class="form-label">Confirm password
                                            </label><span class="text-danger">*</span>
                                            <!-- <input type="password" formControlName="password"
                        [ngClass]="{ 'is-invalid': submitted && myForm.get('password').errors }"
                        [(ngModel)]="confirmPasswords" (change)="passwordsMatch()" placeholder="Confirm Password"
                        id="confirmpassword" class="form-control form-control-md" /> -->
                                        </div>
                                        <!-- <div *ngIf="submitted && myForm.get('password').errors" class="invalid-feedback">
                      <div *ngIf="myForm.get('password').errors.required">confirmPasswords is required.
                      </div>
                    </div> -->
                                    </div>


                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-6 d-flex align-items-center">
                                        <div class="form-outline datepicker w-100">
                                            <label for="aadhar" class="form-label">Address
                                            </label>
                                            <textarea name="" id="" cols="10" rows="3" placeholder="Address"
                                                class="form-control form-control-md"></textarea>
                                            <!-- <input type="text" formControlName="address"   /> -->
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="modal-footer mt-1 pt-2 mb-2" style="text-align: center;padding-right: 50px;">
                  <button type="button" class="btn btn-primary btn-sm" [disabled]="myForm.invalid"
                    (click)="onSubmit(myForm)">Register</button>
                  <button type="button" class="btn btn-danger btn-sm" (click)="cancel()">cancel
  
                  </button>
                </div> -->
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>