// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let apiURL: string = location.origin.includes("localhost") ? "https://sip.samvaadpro.com" : location.origin;

export const environment = {
  production: false,
  // serviceUrl:"http://localhost:56611/api/",
  // fileUrl: "http://localhost:56611/api",
  serviceUrl: `${apiURL}/api/`,
  fileUrl: `${apiURL}/`,
  videoUrl:`${apiURL}`,
  downloadUrl: `${apiURL}/api`,
  SOCKET_ENDPOINT: 'http://localhost:3000',
  SamvaadUrl: 'https://www.samvaadpro.com/conference/',
  RazorPay_Key: 'rzp_live_FuIlkhr9QlHKMl',//Live,
  WebSocket_URL: "https://dev.samvaadpro.com/",
  OpenVidu_URL: "https://openvidu.dhanushinfotech.com:4443",
  reportUrl: 'https://oukdev.samvaadpro.com/oukreports/v1/generate',


  // fileUrl: "https://openuni.samvaadpro.com/",

};
//http://localhost:56608/swagger/index.html   serviceUrl:"https://lmsemsqa.samvaad.pro/api/",

//  serviceUrl:"http://lmsav.dhanushinfotech.com/",https://lmsqa.dhanushinfotech.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
